import { defineStore } from 'pinia';
import { Accessible, SecurityItem } from '@/api/types';
import { SectionsIds } from '@/typings/enums';
import { useAuthStore } from '@/stores/auth';

interface State {
  securityItemsList: SecurityItem[];
  sectionAccessibles: Record<string, Accessible[]>;
}

export const useSecurityStore = defineStore('security', {
  state: (): State => ({
    securityItemsList: [
      {
        accesible: {
          featureIdentifier: SectionsIds.CARE_STORE,
          action: 'READ',
        },
        menuItem: '',
      },
      {
        accesible: {
          featureIdentifier: SectionsIds.BRAND_ANALYTICS,
          action: 'READ',
        },
        menuItem: SectionsIds.BRAND_ANALYTICS,
      },
      {
        accesible: {
          featureIdentifier: SectionsIds.CONSUMER_PRODUCTS,
          action: 'READ',
        },
        menuItem: SectionsIds.CONSUMER_PRODUCTS,
      },
      {
        accesible: {
          featureIdentifier: SectionsIds.CONSUMER_INSIGHTS,
          action: 'READ',
        },
        menuItem: SectionsIds.CONSUMER_INSIGHTS,
      },
      {
        accesible: {
          featureIdentifier: SectionsIds.MARKET_INSIGHTS,
          action: 'READ',
        },
        menuItem: SectionsIds.MARKET_INSIGHTS,
      },
      {
        accesible: {
          featureIdentifier: SectionsIds.CONCEPT_COLLECTION,
          action: 'READ',
        },
        menuItem: SectionsIds.CONCEPT_COLLECTION,
      },
      {
        accesible: {
          featureIdentifier: SectionsIds.FORMULATION_DESIGN,
          action: 'READ',
        },
        menuItem: SectionsIds.FORMULATION_DESIGN,
      },
      {
        accesible: {
          featureIdentifier: SectionsIds.BASF_PRODUCT_DETAILS,
          action: 'READ',
        },
        menuItem: SectionsIds.BASF_PRODUCTS,
      },
      {
        accesible: {
          featureIdentifier: SectionsIds.BASF_PRODUCTS,
          action: 'READ',
        },
        menuItem: SectionsIds.INGREDIENT_SELECTION,
      },
      {
        accesible: { featureIdentifier: SectionsIds.MY_SEARCH, action: 'READ' },
        menuItem: SectionsIds.MY_DLITE,
      },
      {
        accesible: {
          featureIdentifier: SectionsIds.MY_COMPARISON,
          action: 'READ',
        },
        menuItem: SectionsIds.MY_DLITE,
      },
      {
        accesible: {
          featureIdentifier: SectionsIds.MY_PROJECT,
          action: 'READ',
        },
        menuItem: SectionsIds.MY_DLITE,
      },
      {
        accesible: {
          featureIdentifier: SectionsIds.QUICK_SEARCH,
          action: 'READ',
        },
        menuItem: SectionsIds.QUICK_SEARCH,
      },
      {
        accesible: {
          featureIdentifier: SectionsIds.LEARN_MORE,
          action: 'READ',
        },
        menuItem: SectionsIds.LEARN_MORE,
      },
      {
        accesible: {
          featureIdentifier: SectionsIds.EMOLLIENT_JOCKEY,
          action: 'READ',
        },
        menuItem: SectionsIds.EMOLLIENT_JOCKEY,
      },
      {
        accesible: {
          featureIdentifier: SectionsIds.BASF_PRODUCTS,
          action: 'READ',
        },
        menuItem: SectionsIds.BASF_PRODUCTS,
      },
      {
        accesible: {
          featureIdentifier: SectionsIds.EMOLLIENT_MAESTRO,
          action: 'READ',
        },
        menuItem: SectionsIds.EMOLLIENT_MAESTRO,
      },
      {
        accesible: {
          featureIdentifier: SectionsIds.SURFACTANT_NAVIGATOR,
          action: 'READ',
        },
        menuItem: SectionsIds.SURFACTANT_NAVIGATOR,
      },
      {
        accesible: {
          featureIdentifier: SectionsIds.COMPOSITIONS,
          action: 'READ',
        },
        menuItem: SectionsIds.COMPOSITIONS,
      },
      {
        accesible: {
          featureIdentifier: SectionsIds.TREND,
          action: 'READ',
        },
        menuItem: SectionsIds.TREND,
      },
    ],

    sectionAccessibles: {
      [SectionsIds.FORMULATION_DETAILS]: [
        {
          featureIdentifier: 'formulationDesignDetail',
          action: 'READ',
        },
        {
          featureIdentifier: 'formulationDesignFormulationPDF',
          action: 'READ',
        },
        {
          featureIdentifier: 'formulationDesignCompare',
          action: 'READ',
        },
        {
          featureIdentifier: 'formulationDesignRelatedBASFProducts',
          action: 'READ',
        },
        {
          featureIdentifier: 'formulationDesignRelatedConcepts',
          action: 'READ',
        },
        {
          featureIdentifier: 'formulationDesignRelatedConsumerProducts',
          action: 'READ',
        },
        {
          featureIdentifier: 'formulationDesignIngredients',
          action: 'READ',
        },
        {
          featureIdentifier: 'formulationDesignAdditionalInfo',
          action: 'READ',
        },
        {
          featureIdentifier: 'formulationDesignAdditionalInfo',
          action: 'READ',
        },
      ],
    },
  }),

  actions: {
    isUserAllowedMenu(id: string[]): boolean {
      const authStore = useAuthStore();

      if (id === undefined) return false;

      if (authStore.user.fullAccess) return true;

      return this.securityItemsList.some((item) => {
        return (
          id.includes(item.menuItem) &&
          authStore.userAccesiblesIds.includes(item.accesible.featureIdentifier)
        );
      });
    },

    isFeatureAllowed(accessibleId: string): boolean {
      const authStore = useAuthStore();

      if (authStore.user.fullAccess) return true;

      return authStore.userAccesiblesIds.includes(accessibleId);
    },
  },
});
