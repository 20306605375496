<template>
  <div class="header-container">
    <DliteHeaderResponsive
      :is-logged-in="isLoggedIn"
      :show-search-box="showSearchBox"
    >
      <template #config>
        <div
          class="user-configuration"
          :style="{ width: isSmallMobile ? '175px' : '200px' }"
        >
          <button
            class="d-flex justify-space-between align-center"
            @click="doLogout"
          >
            <span>{{ $t('app.common.value.logout') }}</span>
            <img
              :src="`${require('@/assets/icons/lock_open.svg')}`"
              alt="logout"
            />
          </button>
        </div>
      </template>
    </DliteHeaderResponsive>
    <DliteDrawer :apps="appsComputed"></DliteDrawer>
  </div>
</template>

<script setup lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useSecurityStore } from '@/stores/security';
import { EcoSystem } from '@/api/types';
import { SectionsIds } from '@/typings/enums';
import theme from '@/lib/theme';
import { useRouter } from '@/composables/useRouter';
import { useI18n } from '@/composables/useI18n';
import DliteHeaderResponsive from '@/components/common/DliteHeaderResponsive.vue';
import { revokeOAuthToken } from '@/api';
import { trackUserLogginLogout } from '@/lib/analytics';
import { apps } from '@/constants/apps';
import { useResponsive } from '@/composables/useResponsive';
import DliteDrawer from '@/components/common/DliteDrawer.vue';

interface Props {
  isLoggedIn: boolean;
}

withDefaults(defineProps<Props>(), {
  isLoggedIn: false,
});

const { route, router } = useRouter();
const { $t } = useI18n();
const securityStore = useSecurityStore();
const authStore = useAuthStore();
const { isSmallMobile } = useResponsive();

const showSearchBox = computed(
  () =>
    securityStore.isUserAllowedMenu([SectionsIds.QUICK_SEARCH]) &&
    route.value.name !== SectionsIds.MY_SEARCH
);

// AUTH
const doLogout = async () => {
  if (authStore.refreshToken.length > 0) {
    try {
      await revokeOAuthToken({ token: authStore.refreshToken });
      authStore.setRefreshToken('');
      trackUserLogginLogout(false);
      router.push({ name: SectionsIds.LANDING });
    } catch (error) {
      // console.log('Revoke token error');
    }
  } else {
    router.push({ name: SectionsIds.LANDING });
  }
};

const appsComputed = computed((): EcoSystem[] => {
  const appsMap = apps.map((app) => {
    const id = app.id as string;
    const ids = id.split(',');
    return {
      ...app,
      isAllowed: isUserAllowedMenu(ids),
      color:
        theme[ids[0]]?.hexColor || theme[SectionsIds.BRAND_ANALYTICS].hexColor,
    };
  });

  if (!authStore.hasWebshop) {
    appsMap.splice(
      appsMap.findIndex((e) => e.id === SectionsIds.CARE_STORE),
      1
    );
  } else {
    // Check Care Store
    const careStore = appsMap.find((e) => e.id === SectionsIds.CARE_STORE);

    if (careStore) {
      // Update text and link
      careStore.text = authStore.hasBrenntag
        ? 'app.breadcrumbs.brenntag'
        : authStore.hasBasfStore
        ? 'app.breadcrumbs.basfStore'
        : careStore.text;
      careStore.href = getWebshopWelcomePage.value;
    }
  }

  return appsMap;
});

const getWebshopWelcomePage = computed(() => {
  return authStore.user.webshopWelcomePage || '';
});

const isUserAllowedMenu = (id: string[]) => {
  return securityStore.isUserAllowedMenu(id);
};
</script>

<style scoped lang="scss">
.header-container {
  position: relative;
}
.user-configuration {
  display: grid;
  color: $copytext;

  & > div > span {
    font-weight: bold;
  }
  & > div,
  & > button {
    padding: 1rem;
  }

  & > button {
    width: 100%;
    color: $red;
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: text-decoration-color 150ms ease-out;
  }
  & > button:hover {
    text-decoration-color: $red;
  }
  & > button > img {
    margin-inline: 10px;
  }
}
</style>
