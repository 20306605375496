/* eslint-disable  @typescript-eslint/no-explicit-any */
import VueRouter, { RouteConfig, Route } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { useSecurityStore } from '@/stores/security';
import { useLoaderStore } from '@/stores/loader';

import { useComparisonStore } from '@/stores/myComparison';
import { useEmollientMaestroStore } from '@/stores/emollientMaestro';
import { useEmollientJockeyStore } from '@/stores/emollientJockey';
import { SectionsIds } from '@/typings/enums';
import { ANALYTIC_OPTIONS } from '@/mixins/modules/constants/base-constants';
import themeColors from '@/lib/theme';
import { useAppStore } from '@/stores/app';
import { getItemFromStorage, goToFedLogin, setItemStorage } from '@/lib/utils';
import { Login } from '@/typings/enums';
import { isPerformanceConsent } from '@/lib/cookies';
import { trackView } from '@/lib/analytics';
import { AnalyticsView } from '@/api/types';
import { useIngredientStore } from '@/stores/ingredient';

const routes: RouteConfig[] = [
  {
    path: '/',
    name: SectionsIds.LANDING,
    component: () => import('@/views/public/LandingPage.vue'),
    meta: {
      analytics: {
        ignore: true,
      },
    },
  },
  {
    path: '/login',
    name: SectionsIds.LOGIN,
    component: () => import('@/views/public/LoginPage.vue'),
    meta: {
      themeColor: themeColors[SectionsIds.LOGIN].theme,
      bannerColor: themeColors[SectionsIds.LOGIN].hexColor,
      analytics: {
        ignore: true,
      },
    },
  },
  {
    path: '/home',
    name: SectionsIds.HOME,
    component: () => import('@/views/common/HomePage.vue'),
    meta: {
      title: 'app.common.value.name',
      description: 'app.header.descriptions.dlite',
      themeColor: themeColors[SectionsIds.BRAND_ANALYTICS].theme,
      requiresAuth: true,
      analytics: {
        ignore: true,
      },
    },
  },
  {
    path: '/request-bundle',
    name: SectionsIds.REQUEST_BUNDLE,
    meta: {
      themeColor: themeColors[SectionsIds.BRAND_ANALYTICS].theme,
      analytics: {
        ignore: true,
      },
    },
    component: () => import('@/views/public/UserRequestAccess.vue'),
  },
  {
    path: '/ba',
    name: SectionsIds.BRAND_ANALYTICS,
    meta: {
      title: 'app.header.brand-analytics',
      description: 'app.header.descriptions.brand-analytics',
      breadcrumbText: 'app.breadcrumbs.brand-analytics',
      themeColor: themeColors[SectionsIds.BRAND_ANALYTICS].theme,
      bannerColor: themeColors[SectionsIds.BRAND_ANALYTICS].hexColor,
      requiresAuth: true,
      roles: [SectionsIds.BRAND_ANALYTICS],
      analytics: {
        category: ANALYTIC_OPTIONS.SECTIONS.BRAND_ANALYTICS,
        name: ANALYTIC_OPTIONS.SUB_SECTIONS.BRAND_LIST,
        type: ANALYTIC_OPTIONS.PAGE_TYPE.SELECTION,
      },
    },
    component: () => import('@/views/brandAnalytics/BrandAnalytics.vue'),
    children: [
      {
        path: 'details/:brandId',
        name: SectionsIds.BRAND_DETAILS,
        props: true,
        component: () => import('@/views/brandAnalytics/BrandDetails.vue'),
        meta: {
          title: 'app.header.brand-details',
          description: 'app.header.descriptions.brand-analytics',
          analytics: {
            category: ANALYTIC_OPTIONS.SECTIONS.BRAND_ANALYTICS,
            subcategory: ANALYTIC_OPTIONS.PAGE_SUBCATEGORY.BRAND_DETAILS,
            name: '', // Brand name
            type: ANALYTIC_OPTIONS.PAGE_TYPE.PRODUCT,
          },
        },
      },
    ],
  },
  {
    path: '/is',
    name: SectionsIds.INGREDIENT_SELECTION,
    meta: {
      title: 'app.header.ingredient-selection',
      description: 'app.header.descriptions.ingredient-selection',
      breadcrumbText: 'app.breadcrumbs.ingredient-selection',
      themeColor: themeColors[SectionsIds.INGREDIENT_SELECTION].theme,
      bannerColor: themeColors[SectionsIds.INGREDIENT_SELECTION].hexColor,
      requiresAuth: true,
      roles: [SectionsIds.INGREDIENT_SELECTION, SectionsIds.BASF_PRODUCTS],
      analytics: {
        category: ANALYTIC_OPTIONS.SECTIONS.INGREDIENT_SELECTION,
        name: ANALYTIC_OPTIONS.SUB_SECTIONS.BASF_PRODUCTS,
        type: ANALYTIC_OPTIONS.PAGE_TYPE.SELECTION,
      },
    },
    component: () =>
      import('@/views/ingredientSelection/IngredientSelection.vue'),
    children: [
      {
        path: 'details/:ingredientId/:typeId',
        name: SectionsIds.INGREDIENT_DETAILS,
        props: true,
        component: () => import('@/views/ingredients/IngredientDetails.vue'),
        meta: {
          title: 'app.page.ingredient-details.pageTitle',
          description: 'app.header.descriptions.ingredient-details',
          requiresAuth: true,
          roles: [SectionsIds.INGREDIENT_SELECTION],
          analytics: {
            category: ANALYTIC_OPTIONS.SECTIONS.INGREDIENT_SELECTION,
            subcategory: ANALYTIC_OPTIONS.PAGE_SUBCATEGORY.INGREDIENT_DETAILS,
            name: '', // INCI name
            type: ANALYTIC_OPTIONS.PAGE_TYPE.PRODUCT,
          },
        },
      },
      {
        path: 'bp/details/:basfProductId',
        name: SectionsIds.BASF_PRODUCT_DETAILS,
        props: true,
        meta: {
          title: 'app.page.basf-product-details.pageTitle',
          description: 'app.header.descriptions.basf-product-details',
          breadcrumbText: 'app.breadcrumbs.ingredient-selection',
          themeColor: themeColors[SectionsIds.INGREDIENT_SELECTION].theme,
          bannerColor: themeColors[SectionsIds.INGREDIENT_SELECTION].hexColor,
          requiresAuth: true,
          roles: [SectionsIds.BASF_PRODUCTS],
          analytics: {
            category: ANALYTIC_OPTIONS.SECTIONS.INGREDIENT_SELECTION,
            subcategory: ANALYTIC_OPTIONS.PAGE_SUBCATEGORY.BASF_PRODUCT_DETAIL,
            name: '', // BASF product name
            type: ANALYTIC_OPTIONS.PAGE_TYPE.PRODUCT,
          },
        },
        component: () => import('@/views/basfProducts/BasfProductDetails.vue'),
      },
    ],
  },
  {
    path: '/ir',
    name: SectionsIds.COMPOSITIONS,
    meta: {
      title: 'app.header.ingredients-revealed',
      description: 'app.header.descriptions.ingredients-revealed',
      breadcrumbText: 'app.breadcrumbs.ingredients-revealed',
      themeColor: themeColors[SectionsIds.COMPOSITIONS].theme,
      bannerColor: themeColors[SectionsIds.COMPOSITIONS].hexColor,
      requiresAuth: true,
      roles: [SectionsIds.COMPOSITIONS],
      analytics: {
        category: ANALYTIC_OPTIONS.SECTIONS.COMPOSITIONS,
        name: 'Home', // Tab name
        type: ANALYTIC_OPTIONS.PAGE_TYPE.SELECTION,
      },
    },
    component: () => import('@/views/compositions/CompositionsMain.vue'),
  },
  {
    path: '/cp',
    name: SectionsIds.CONSUMER_PRODUCTS,
    meta: {
      title: 'app.header.consumer-products',
      description: 'app.header.descriptions.consumer-products',
      breadcrumbText: 'app.breadcrumbs.consumer-products',
      themeColor: themeColors[SectionsIds.CONSUMER_PRODUCTS].theme,
      bannerColor: themeColors[SectionsIds.CONSUMER_PRODUCTS].hexColor,
      requiresAuth: true,
      roles: [SectionsIds.CONSUMER_PRODUCTS],
      analytics: {
        category: ANALYTIC_OPTIONS.SECTIONS.CONSUMER_PRODUCTS,
        name: ANALYTIC_OPTIONS.SUB_SECTIONS.PRODUCT_LIST,
        type: ANALYTIC_OPTIONS.PAGE_TYPE.SELECTION,
      },
    },
    component: () => import('@/views/consumerProducts/ConsumerProducts.vue'),
    children: [
      {
        path: 'details/:productId',
        name: SectionsIds.PRODUCT_DETAILS,
        props: true,
        component: () => import('@/views/consumerProducts/ProductDetails.vue'),
        meta: {
          title: 'app.header.product-details',
          description: 'app.header.descriptions.product-details',
          analytics: {
            category: ANALYTIC_OPTIONS.SECTIONS.CONSUMER_PRODUCTS,
            subcategory:
              ANALYTIC_OPTIONS.PAGE_SUBCATEGORY.CONSUMER_PRODUCT_DETAIL,
            name: '', // Product name
            type: ANALYTIC_OPTIONS.PAGE_TYPE.PRODUCT,
          },
        },
      },
    ],
  },
  {
    path: '/ci',
    name: SectionsIds.CONSUMER_INSIGHTS,
    meta: {
      title: 'app.header.top-ranking',
      description: 'app.header.descriptions.top-ranking',
      breadcrumbText: 'app.breadcrumbs.consumer-insights',
      themeColor: themeColors[SectionsIds.CONSUMER_INSIGHTS].theme,
      bannerColor: themeColors[SectionsIds.CONSUMER_INSIGHTS].hexColor,
      requiresAuth: true,
      roles: [SectionsIds.CONSUMER_INSIGHTS],
      analytics: {
        category: ANALYTIC_OPTIONS.SECTIONS.CONSUMER_INSIGHTS,
        name: ANALYTIC_OPTIONS.SUB_SECTIONS.TOP_DISCUSSED_TOPICS, // Tab name
        type: ANALYTIC_OPTIONS.PAGE_TYPE.SELECTION,
      },
    },
    component: () => import('@/views/consumerInsights/ConsumerInsights.vue'),
  },
  {
    path: '/ci-mo',
    name: SectionsIds.MARKET_INSIGHTS,
    meta: {
      title: 'app.header.market-overview',
      description: 'app.header.descriptions.market-overview',
      breadcrumbText: 'app.breadcrumbs.market-insights',
      themeColor: themeColors[SectionsIds.MARKET_INSIGHTS].theme,
      bannerColor: themeColors[SectionsIds.MARKET_INSIGHTS].hexColor,
      requiresAuth: true,
      roles: [SectionsIds.MARKET_INSIGHTS],
      analytics: {
        category: ANALYTIC_OPTIONS.SECTIONS.MARKET_INSIGHTS,
        name: ANALYTIC_OPTIONS.SECTIONS.MARKET_INSIGHTS,
        type: ANALYTIC_OPTIONS.PAGE_TYPE.SELECTION,
      },
    },
    props: true,
    component: () => import('@/views/marketOverview/MarketOverview.vue'),
  },
  {
    path: '/cc',
    name: SectionsIds.CONCEPT_COLLECTION,
    meta: {
      title: 'app.header.concept-collection',
      description: 'app.header.descriptions.concept-collection',
      breadcrumbText: 'app.breadcrumbs.concept-collection',
      themeColor: themeColors[SectionsIds.CONCEPT_COLLECTION].theme,
      bannerColor: themeColors[SectionsIds.CONCEPT_COLLECTION].hexColor,
      requiresAuth: true,
      roles: [SectionsIds.CONCEPT_COLLECTION],
      analytics: {
        category: ANALYTIC_OPTIONS.SECTIONS.CONCEPT_COLLECTION,
        name: ANALYTIC_OPTIONS.SUB_SECTIONS.NEW_CONCEPTS,
        type: ANALYTIC_OPTIONS.PAGE_TYPE.SELECTION,
      },
    },
    component: () => import('@/views/conceptCollection/ConceptCollection.vue'),
    children: [
      {
        path: 'details/:conceptId',
        name: SectionsIds.CONCEPT_DETAILS,
        props: true,
        meta: {
          title: 'app.header.concept-collection',
          description: 'app.header.descriptions.concept-collection',
          analytics: {
            category: ANALYTIC_OPTIONS.SECTIONS.CONCEPT_COLLECTION,
            subcategory:
              ANALYTIC_OPTIONS.PAGE_SUBCATEGORY.CONCEPT_COLLECTION_DETAIL,
            name: '', // Concept name
            type: ANALYTIC_OPTIONS.PAGE_TYPE.PRODUCT,
          },
        },
        component: () =>
          import('@/views/conceptCollection/ConceptCollectionDetails.vue'),
      },
    ],
  },
  {
    path: '/trends',
    name: SectionsIds.TREND,
    meta: {
      title: 'app.header.trends',
      description: 'app.header.descriptions.trends',
      breadcrumbText: 'app.breadcrumbs.trends',
      themeColor: themeColors[SectionsIds.TREND].theme,
      bannerColor: themeColors[SectionsIds.TREND].hexColor,
      requiresAuth: true,
      roles: [SectionsIds.TREND],
      analytics: {
        category: ANALYTIC_OPTIONS.SECTIONS.TRENDS,
        name: ANALYTIC_OPTIONS.SUB_SECTIONS.NEW_TRENDS,
        type: ANALYTIC_OPTIONS.PAGE_TYPE.SELECTION,
      },
    },
    component: () => import('@/views/trends/TrendsMain.vue'),
    children: [
      {
        path: 'details/:trendId',
        name: SectionsIds.TREND_DETAILS,
        props: true,
        meta: {
          title: 'app.header.trends',
          description: 'app.header.descriptions.trends',
          analytics: {
            category: ANALYTIC_OPTIONS.SECTIONS.TRENDS,
            subcategory: ANALYTIC_OPTIONS.PAGE_SUBCATEGORY.TREND_DETAIL,
            name: '', // Trend name
            type: ANALYTIC_OPTIONS.PAGE_TYPE.PRODUCT,
          },
        },
        component: () => import('@/views/trends/TrendsDetails.vue'),
      },
    ],
  },
  {
    path: '/fd',
    name: SectionsIds.FORMULATION_DESIGN,
    meta: {
      title: 'app.header.formulation-design',
      description: 'app.header.descriptions.formulation-design',
      breadcrumbText: 'app.breadcrumbs.formulation-design',
      themeColor: themeColors[SectionsIds.FORMULATION_DESIGN].theme,
      bannerColor: themeColors[SectionsIds.FORMULATION_DESIGN].hexColor,
      requiresAuth: true,
      roles: [SectionsIds.FORMULATION_DESIGN],
      analytics: {
        category: ANALYTIC_OPTIONS.SECTIONS.FORMULATION_DESIGN,
        name: ANALYTIC_OPTIONS.SUB_SECTIONS.FORMULATIONS_OVERVIEW,
        type: ANALYTIC_OPTIONS.PAGE_TYPE.SELECTION,
      },
    },
    component: () => import('@/views/formulations/Formulations.vue'),
    children: [
      {
        path: 'details/:id',
        name: SectionsIds.FORMULATION_DETAILS,
        props: true,
        meta: {
          title: 'app.page.formulation-details.pageTitle',
          description: 'app.header.descriptions.formulation-details',
          analytics: {
            category: ANALYTIC_OPTIONS.SECTIONS.FORMULATION_DESIGN,
            subcategory: ANALYTIC_OPTIONS.PAGE_SUBCATEGORY.FORMULATION_DETAIL,
            name: '', // Formulation name
            type: ANALYTIC_OPTIONS.PAGE_TYPE.PRODUCT,
          },
        },
        component: () => import('@/views/formulations/FormulationDetails.vue'),
      },
    ],
  },
  {
    path: '/md',
    meta: {
      title: 'app.header.my-dlite',
      description: 'app.header.descriptions.my-dlite',
      breadcrumbText: 'app.breadcrumbs.my-dlite',
      themeColor: themeColors[SectionsIds.MY_DLITE].theme,
      bannerColor: themeColors[SectionsIds.BRAND_ANALYTICS].hexColor,
      requiresAuth: true,
      roles: [SectionsIds.MY_DLITE],
    },
    component: () => import('@/views/myDlite/MyDlite.vue'),
    children: [
      {
        path: '',
        name: SectionsIds.MY_DLITE,
        redirect: 'ms',
      },
      {
        path: 'ms',
        name: SectionsIds.MY_SEARCH,
        meta: {
          title: 'app.header.my-dlite',
          description: 'app.header.descriptions.my-search',
          analytics: {
            category: ANALYTIC_OPTIONS.SECTIONS.MY_DLITE,
            name: ANALYTIC_OPTIONS.SUB_SECTIONS.MY_SEARCH,
            type: ANALYTIC_OPTIONS.PAGE_TYPE.SELECTION,
          },
        },
        component: () => import('@/views/myDlite/MySearch.vue'),
      },
      {
        path: 'mc',
        name: SectionsIds.MY_COMPARISON,
        props: true,
        meta: {
          title: 'app.header.my-dlite',
          description: 'app.header.descriptions.my-comparison',
          analytics: {
            category: ANALYTIC_OPTIONS.SECTIONS.MY_DLITE,
            name: ANALYTIC_OPTIONS.SUB_SECTIONS.MY_COMPARISON,
            type: ANALYTIC_OPTIONS.PAGE_TYPE.SELECTION,
          },
        },
        component: () => import('@/views/myDlite/MyComparison.vue'),
      },
      {
        path: 'mp',
        name: SectionsIds.MY_PROJECT,
        props: true,
        meta: {
          title: 'app.header.my-dlite',
          description: 'app.header.descriptions.my-project',
          analytics: {
            category: ANALYTIC_OPTIONS.SECTIONS.MY_DLITE,
            name: ANALYTIC_OPTIONS.SUB_SECTIONS.MY_PROJECTS,
            type: ANALYTIC_OPTIONS.PAGE_TYPE.SELECTION,
          },
        },
        component: () => import('@/views/myDlite/MyProjects.vue'),
      },
    ],
  },
  {
    path: '/auth/callback',
    name: SectionsIds.AUTH_CALLBACK,
    meta: {
      analytics: {
        ignore: true,
      },
    },
    component: () => import('@/views/common/AuthPage.vue'),
  },
  {
    path: '/public',
    meta: {
      themeColor: themeColors[SectionsIds.MY_DLITE].theme,
      analytics: {
        ignore: true,
      },
    },
    component: () => import('@/views/public/PublicOverview.vue'),
    children: [
      {
        path: '',
        name: SectionsIds.PUBLIC_BASE,
        meta: {
          analytics: {
            ignore: true,
          },
        },
        redirect: '/',
      },
      {
        path: 'contact',
        name: SectionsIds.CONTACT_OVERVIEW,
        meta: {
          title: 'app.footer.contact',
          description: 'app.header.descriptions.contact',
          breadcrumbText: 'app.breadcrumbs.contact',
          analytics: {
            ignore: true,
          },
        },
        component: () => import('@/views/public/ContactOverview.vue'),
      },
      {
        path: 'terms-of-use',
        name: SectionsIds.TERMS_OVERVIEW,
        meta: {
          title: 'app.footer.terms-of-use',
          description: 'app.header.descriptions.terms-of-use',
          breadcrumbText: 'app.breadcrumbs.terms-of-use',
          analytics: {
            ignore: true,
          },
        },
        component: () => import('@/views/public/TermsOverview.vue'),
      },
      {
        path: 'privacy-statement',
        name: SectionsIds.PRIVACY_OVERVIEW,
        meta: {
          title: 'app.footer.privacy-statement',
          description: 'app.header.descriptions.privacy-statement',
          breadcrumbText: 'app.breadcrumbs.privacy',
          analytics: {
            ignore: true,
          },
        },
        component: () => import('@/views/public/PrivacyOverview.vue'),
      },
      {
        path: 'imprint',
        name: SectionsIds.IMPRINT_OVERVIEW,
        meta: {
          title: 'app.footer.imprint',
          description: 'app.header.descriptions.imprint',
          breadcrumbText: 'app.breadcrumbs.imprint',
          analytics: {
            ignore: true,
          },
        },
        component: () => import('@/views/public/ImprintOverview.vue'),
      },
      {
        path: 'marketing-consent',
        name: SectionsIds.MARKETING_OVERVIEW,
        meta: {
          analytics: {
            ignore: true,
          },
        },
        component: () => import('@/views/public/MarketingOverview.vue'),
      },
      {
        path: 'register',
        name: SectionsIds.USER_REGISTRATION,
        meta: {
          breadcrumbText: 'app.breadcrumbs.my-dlite',
          analytics: {
            ignore: true,
          },
        },
        component: () => import('@/views/public/UserRegistration.vue'),
      },
      {
        path: 'registered',
        name: SectionsIds.USER_REGISTRATION_SUCCESS_PAGE,
        meta: {
          breadcrumbText: 'app.breadcrumbs.my-dlite',
          analytics: {
            ignore: true,
          },
        },
        component: () =>
          import('@/views/public/UserRegistrationSuccessfullyPage.vue'),
      },
      {
        path: 'error',
        name: SectionsIds.ERROR_PAGE,
        meta: {
          breadcrumbText: 'app.breadcrumbs.my-dlite',
          analytics: {
            ignore: true,
          },
        },
        component: () => import('@/views/public/ErrorPage.vue'),
      },
    ],
  },
  {
    path: '/lm',
    name: SectionsIds.LEARN_MORE,
    meta: {
      title: 'app.header.learn-more',
      description: 'app.header.descriptions.learn-more',
      breadcrumbText: 'app.breadcrumbs.learn-more',
      themeColor: themeColors[SectionsIds.LEARN_MORE].theme,
      requiresAuth: true,
      roles: [SectionsIds.LEARN_MORE],
      analytics: {
        category: ANALYTIC_OPTIONS.SECTIONS.LEARN_MORE,
        name: ANALYTIC_OPTIONS.SECTIONS.LEARN_MORE,
        type: ANALYTIC_OPTIONS.PAGE_TYPE.SELECTION,
      },
    },
    component: () => import('@/views/learnMore/LearnMore.vue'),
  },
  {
    path: '/sn',
    name: SectionsIds.SURFACTANT_NAVIGATOR,
    meta: {
      title: 'app.header.surfactant-navigator',
      description: 'app.header.descriptions.surfactant-navigator',
      breadcrumbText: 'app.breadcrumbs.surfactant-navigator',
      themeColor: themeColors[SectionsIds.SURFACTANT_NAVIGATOR].theme,
      bannerColor: themeColors[SectionsIds.SURFACTANT_NAVIGATOR].hexColor,
      requiresAuth: true,
      roles: [SectionsIds.SURFACTANT_NAVIGATOR],
      analytics: {
        category: ANALYTIC_OPTIONS.SECTIONS.SURFACTANT_NAVIGATOR,
        name: ANALYTIC_OPTIONS.SUB_SECTIONS.SURFACTANT_FINDER, // Tab name
        type: ANALYTIC_OPTIONS.PAGE_TYPE.SELECTION,
      },
    },
    component: () => import('@/views/surfactant/SurfactantMain.vue'),
  },
  {
    path: '/ej',
    meta: {
      title: 'app.header.emollient-jockey',
      description: 'app.header.descriptions.emollient-jockey',
      breadcrumbText: 'app.breadcrumbs.emollient-jockey',
      themeColor: themeColors[SectionsIds.EMOLLIENT_JOCKEY].theme,
      requiresAuth: true,
      roles: [SectionsIds.EMOLLIENT_JOCKEY],
    },
    component: () => import('@/views/emollientJockey/EmollientJockey.vue'),
    children: [
      {
        path: '',
        name: SectionsIds.EMOLLIENT_JOCKEY,
        redirect: 'list',
      },
      {
        path: 'list',
        name: SectionsIds.EMOLLIENT_JOCKEY_LIST,
        meta: {
          title: 'app.header.emollient-jockey',
          description: 'app.header.descriptions.emollient-jockey',
          analytics: {
            category: ANALYTIC_OPTIONS.SECTIONS.EMOLLIENT_JOCKEY,
            name: ANALYTIC_OPTIONS.SUB_SECTIONS.EMOLLIENT_JOCKEY_LIST,
            type: ANALYTIC_OPTIONS.PAGE_TYPE.SELECTION,
          },
        },
        component: () =>
          import('@/views/emollientJockey/EmollientJockeyListCharter.vue'),
      },
      {
        path: 'radar',
        name: SectionsIds.EMOLLIENT_JOCKEY_RADAR,
        meta: {
          title: 'app.header.emollient-jockey',
          description: 'app.header.descriptions.emollient-jockey',
          analytics: {
            category: ANALYTIC_OPTIONS.SECTIONS.EMOLLIENT_JOCKEY,
            name: ANALYTIC_OPTIONS.SUB_SECTIONS.EMOLLIENT_JOCKEY_RADAR,
            type: ANALYTIC_OPTIONS.PAGE_TYPE.SELECTION,
          },
        },
        component: () =>
          import('@/views/emollientJockey/EmollientJockeyRadarCharter.vue'),
      },
      {
        path: 'matrix',
        name: SectionsIds.EMOLLIENT_JOCKEY_MATRIX,
        meta: {
          title: 'app.header.emollient-jockey',
          description: 'app.header.descriptions.emollient-jockey',
          analytics: {
            category: ANALYTIC_OPTIONS.SECTIONS.EMOLLIENT_JOCKEY,
            name: ANALYTIC_OPTIONS.SUB_SECTIONS.EMOLLIENT_JOCKEY_MATRIX,
            type: ANALYTIC_OPTIONS.PAGE_TYPE.SELECTION,
          },
        },
        component: () =>
          import('@/views/emollientJockey/EmollientJockeyMatrixCharter.vue'),
      },
    ],
  },
  {
    path: '/em',
    meta: {
      title: 'app.header.emollient-maestro',
      description: 'app.header.descriptions.emollient-maestro',
      breadcrumbText: 'app.breadcrumbs.emollient-maestro',
      themeColor: themeColors[SectionsIds.EMOLLIENT_MAESTRO].theme,
      requiresAuth: true,
      roles: [SectionsIds.EMOLLIENT_MAESTRO],
    },
    component: () => import('@/views/emollientMaestro/EmollientMaestro.vue'),
    children: [
      {
        path: '',
        name: SectionsIds.EMOLLIENT_MAESTRO,
        redirect: 'easy',
      },
      {
        path: 'easy',
        name: SectionsIds.EMOLLIENT_MAESTRO_EASY_MODE,
        props: true,
        meta: {
          title: 'app.header.emollient-maestro',
          description: 'app.header.descriptions.emollient-maestro',
          analytics: {
            category: ANALYTIC_OPTIONS.SECTIONS.EMOLLIENT_MAESTRO,
            name: ANALYTIC_OPTIONS.SUB_SECTIONS.EMOLLIENT_MAESTRO_EASY,
            type: ANALYTIC_OPTIONS.PAGE_TYPE.SELECTION,
          },
        },
        component: () =>
          import('@/views/emollientMaestro/EmollientMaestroEasy.vue'),
      },
      {
        path: 'advanced',
        name: SectionsIds.EMOLLIENT_MAESTRO_ADVANCED_MODE,
        props: true,
        meta: {
          title: 'app.header.emollient-maestro',
          description: 'app.header.descriptions.emollient-maestro',
          analytics: {
            category: ANALYTIC_OPTIONS.SECTIONS.EMOLLIENT_MAESTRO,
            name: ANALYTIC_OPTIONS.SUB_SECTIONS.EMOLLIENT_MAESTRO_ADVANCED,
            type: ANALYTIC_OPTIONS.PAGE_TYPE.SELECTION,
          },
        },
        component: () =>
          import('@/views/emollientMaestro/EmollientMaestroAdvanced.vue'),
      },
      {
        path: 'advanced/:id',
        name: SectionsIds.EMOLLIENT_MAESTRO_ADVANCED_MODE_CANDIDATE,
        props: true,
        meta: {
          title: 'app.header.emollient-maestro',
          description: 'app.header.descriptions.emollient-maestro',
          analytics: {
            ignore: true,
          },
        },
        component: () =>
          import(
            '@/views/emollientMaestro/EmollientMaestroAdvancedCandidate.vue'
          ),
      },
    ],
  },
  {
    path: '*',
    name: SectionsIds.DEFAULT,
    meta: {
      title: 'app.common.value.name',
      description: 'app.header.descriptions.dlite',
      analytics: {
        ignore: true,
      },
    },
    component: () => import('@/views/common/ErrorPage.vue'),
  },
];

const router = new VueRouter({
  scrollBehavior: () => ({
    x: 0,
    y: 0,
  }),
  mode: 'history',
  routes,
  base: process.env.NODE_ENV === 'production' ? '/ui' : '',
});

const routesEmollientsNeeded = [
  SectionsIds.PRODUCT_DETAILS,
  SectionsIds.INGREDIENT_DETAILS,
  SectionsIds.EMOLLIENT_MAESTRO_EASY_MODE,
  SectionsIds.EMOLLIENT_MAESTRO_ADVANCED_MODE,
  SectionsIds.MY_COMPARISON,
  SectionsIds.EMOLLIENT_JOCKEY_RADAR,
];

const updateThemeTransition = (from: Route, to: Route) => {
  const appStore = useAppStore();

  // Add delay to avoid changing the colors while staying in the same page
  const delay = appStore.themeColor === 'default' ? 0 : 100;
  setTimeout(() => {
    appStore.changeTheme(to);
  }, delay);

  if (
    to.name === SectionsIds.LANDING ||
    to.name === SectionsIds.LOGIN ||
    to.name === SectionsIds.HOME
  ) {
    appStore.setTransitionName('fade');
    return;
  }

  const toDepth = to.path.split('/').length;
  const fromDepth = from.path.split('/').length;
  const transitionName = toDepth < fromDepth ? 'slide-left' : 'slide-right';
  appStore.setTransitionName(transitionName);
};

const setRedirectPathAfterLogin = (redirectPathAfterLogin) => {
  setItemStorage('redirectPathAfterLogin', redirectPathAfterLogin);
};

const getRedirectPathAfterLogin = () => {
  return getItemFromStorage('redirectPathAfterLogin');
};

const deleteRedirectPathAfterLogin = () => {
  localStorage.removeItem('redirectPathAfterLogin');
};

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const loaderStore = useLoaderStore();
  const securityStore = useSecurityStore();
  const emollientJockeyStore = useEmollientJockeyStore();
  const emollientMaestroModule = useEmollientMaestroStore();
  const myComparisonStore = useComparisonStore();

  const requiresAuth = to.matched.some((m) => m.meta.requiresAuth);

  if (requiresAuth) {
    loaderStore.resetLoading();
    if (!authStore.isLoggedIn && !authStore.token) {
      if (to.name !== SectionsIds.HOME) {
        const redirectPathAfterLogin = to.fullPath as string;
        setRedirectPathAfterLogin(redirectPathAfterLogin);
      }
      next('/login');
    } else {
      if (authStore.isEmptyUser && authStore.token) {
        await authStore.fetchUserinfosMe();
      }
      if (!myComparisonStore.itemsLoaded) {
        myComparisonStore.loadItems();
      }
      const pathRoles =
        to.meta?.roles || to.matched.find((m) => m.meta.roles)?.meta.roles;

      if (
        to.name === SectionsIds.HOME ||
        securityStore.isUserAllowedMenu(pathRoles)
      ) {
        if (routesEmollientsNeeded.includes(to.name as SectionsIds)) {
          // Fetch emollient info if needed
          if (
            !Object.keys(emollientMaestroModule.beckmarksAlternatives).length
          ) {
            await emollientMaestroModule.fetchAlternativesBenchmarks();
            emollientJockeyStore.getEmollientJockeyBenchmarks();
          }
        }

        const redirectPath = getRedirectPathAfterLogin();
        if (redirectPath) {
          deleteRedirectPathAfterLogin();
          router.push(redirectPath);
        } else {
          next();
        }
      } else {
        next('/public/error');
      }
    }
  } else {
    if (to.path === '/autologin') {
      const redirectPathAfterLogin = to.query.redirect as string;
      if (redirectPathAfterLogin) {
        setRedirectPathAfterLogin(redirectPathAfterLogin);
        const loginType = to.query.internal ? Login.INTERNAL : Login.EXTERNAL;
        goToFedLogin(loginType);
      } else {
        next('/');
      }
      return false;
    } else if (to.name === SectionsIds.AUTH_CALLBACK) {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (code) {
        let time = localStorage.getItem('dliteLoginAttempt');
        if (time && Date.now() < parseInt(time)) {
          localStorage.removeItem('dliteLoginAttempt');
          time = null;
        }

        if (!time || (time && Date.now() - parseInt(time) >= 10000)) {
          localStorage.setItem('dliteLoginAttempt', Date.now().toString());
          await authStore.getAuthToken(code);

          const redirectPath = getRedirectPathAfterLogin();
          // If user comes from autologin
          if (redirectPath) {
            next(redirectPath);
            deleteRedirectPathAfterLogin();
          } else {
            next('/home');
          }
        }
      } else if (from.name === SectionsIds.LOGIN) {
        // TODO: check if needed, to do the redirect using the same callback url
        next();
      } else {
        next('/');
      }
      return false;
    } else {
      if (to.name === SectionsIds.LANDING || to.name === SectionsIds.LOGIN) {
        await authStore.cleanUser();
        next();
      } else {
        // Public pages
        next();
      }
    }
  }

  updateThemeTransition(from, to);
});

const detailPageNames = [
  SectionsIds.BRAND_DETAILS,
  SectionsIds.PRODUCT_DETAILS,
  SectionsIds.FORMULATION_DETAILS,
  SectionsIds.BASF_PRODUCT_DETAILS,
  SectionsIds.INGREDIENT_DETAILS,
];

router.afterEach(async (to, from) => {
  if (!isPerformanceConsent() || to.meta.analytics?.ignore) return;

  const analyticsInfo = to.meta.analytics;

  if (
    detailPageNames.includes(to.name as SectionsIds) &&
    to.params.hiddenLabel
  ) {
    analyticsInfo.name = to.params.hiddenLabel;
  }

  if (
    from.name === SectionsIds.MY_SEARCH &&
    to.params.section === SectionsIds.INGREDIENT_SELECTION
  ) {
    // Set store tab for INCIs and update analytics name
    const ingredientsStore = useIngredientStore();
    ingredientsStore.setActiveTab(SectionsIds.INGREDIENT_SELECTION);
    analyticsInfo.name = ANALYTIC_OPTIONS.SUB_SECTIONS.INCI;
  }

  const pageName = analyticsInfo.name || to.params.hiddenLabel;
  const view: AnalyticsView = {
    page_category: analyticsInfo.category,
    page_identifier: ANALYTIC_OPTIONS.PAGE_IDENTIFIER.APP,
    page_type: analyticsInfo.type,
    page_name: pageName,
    ...(analyticsInfo.subcategory && {
      page_subcategory: analyticsInfo.subcategory,
    }),
    ...(to.name === SectionsIds.BASF_PRODUCT_DETAILS && {
      product_name: pageName,
      product_prd: to.params.prdNumber,
    }),
  };

  trackView(view);
});

export default router;
