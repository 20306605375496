import { INGREDIENT_BLACK_LIST } from '@/mixins';
import { ENV, Login, Order, SectionsIds } from '@/typings/enums';
import vuetify from '@/plugins/vuetify';
import {
  Category,
  DataRange,
  Item,
  ItemLabel,
  ItemLabelCategory,
} from '@/api/types';
import { NavigationModule } from '@/typings/menu';
import { useI18n } from '@/composables/useI18n';
import { useAuthStore } from '@/stores/auth';
import moment from 'moment';

const { $t, locale } = useI18n();

/* eslint-disable @typescript-eslint/no-explicit-any */
const throttle = (func: any, timeout = 40) => {
  let ready = true;

  return (...args: any[]) => {
    if (!ready) {
      return;
    }

    ready = false;
    func(...args);
    setTimeout(() => {
      ready = true;
    }, timeout);
  };
};

const goToFedLogin = (type: number) => {
  let uri = process.env.VUE_APP_FED_URL || '';
  if (!uri) return;

  uri =
    type === Login.INTERNAL
      ? `${uri}&acr_values=`
      : `${uri}&acr_values=3IAM/Login/External`;

  location.href = encodeURI(uri);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const compare = (fieldA: any, fieldB: any, order = 'DESC', type = 'string') => {
  if (type === 'string') {
    return order === 'DESC'
      ? fieldB > fieldA
        ? 1
        : -1
      : fieldB > fieldA
      ? -1
      : 1;
  } else {
    return order === 'DESC' ? fieldB - fieldA : fieldA - fieldB;
  }
};

const isArrayEqual = (arr1: string[] | number[], arr2: string[] | number[]) => {
  return (
    arr1.length === arr2.length &&
    JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort())
  );
};

/**
 * null || undefined check.
 */
const isNill = (item: any): boolean => {
  return item === null || item === undefined;
};

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
const isObject = (item: any): boolean => {
  return item && typeof item === 'object' && !Array.isArray(item);
};

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
const mergeDeep = (target: any, ...sources: any[]): any => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
};

const bindChartEvents = (myChart: any, containerElement: any) => {
  const legendItemSelector = '.legend-item';
  const labelSeletor = '.label';

  const legendItems = [
    ...containerElement.querySelectorAll(legendItemSelector),
  ];
  legendItems.forEach((item, i) => {
    item.addEventListener('click', (e: any) =>
      updateDataset(e.target.parentNode, i)
    );

    item.addEventListener('mouseenter', () => showItemTooltip(i));

    item.addEventListener('mouseleave', () => hideItemTooltip());
  });

  const updateDataset = (currentEl: any, index: any) => {
    const meta = myChart.getDatasetMeta(0);
    const labelEl = currentEl.querySelector(labelSeletor);
    const result = meta.data[index].hidden === true ? false : true;
    if (result === true) {
      meta.data[index].hidden = true;
      labelEl.classList.add('label--hidden');
    } else {
      meta.data[index].hidden = false;
      labelEl.classList.remove('label--hidden');
    }
    myChart.update();
  };

  const showItemTooltip = (index: any) => {
    const segment = myChart.getDatasetMeta(0).data[index];
    myChart.tooltip._active = [segment];
    myChart.tooltip.update();
    myChart.draw();
  };

  const hideItemTooltip = () => {
    myChart.tooltip._active = [];
    myChart.tooltip.update();
    myChart.draw();
  };
};

/**
 * Check if ingredient is blacklisted
 * @param ingredientName
 * @returns {boolean}
 */
const isIngredientBlacklisted = (ingredientName: string) => {
  return INGREDIENT_BLACK_LIST.includes(ingredientName.toLowerCase());
};

const getEnvironment = () => {
  return {
    isLocal: process.env.VUE_APP_ENVIRONMENT === ENV.LOCAL,
    isDev: process.env.VUE_APP_ENVIRONMENT === ENV.DEV,
    isQA: process.env.VUE_APP_ENVIRONMENT === ENV.QA,
    isProd: process.env.VUE_APP_ENVIRONMENT === ENV.PROD,
  };
};

const getI18nText = (key: string) => {
  return $t(key);
};

const getBreakPointColCount = (): number => {
  switch (vuetify.framework.breakpoint.name) {
    case 'xs':
      return 2;
    case 'sm':
      return 3;
    case 'md':
      return 3;
    case 'lg':
      return 4;
    case 'xl':
    default:
      return 4;
  }
};

const getLetterLength = (): number => {
  switch (vuetify.framework.breakpoint.name) {
    case 'xs':
      return 5;
    case 'sm':
      return 10;
    case 'md':
      return 15;
    case 'lg':
      return 15;
    case 'xl':
      return 20;
    default:
      return 10;
  }
};

/**
 * Trim string to desired length and fill middle space with filler
 * @param text
 * @param filler
 * @param maxLength
 * @returns {string}
 */
const formatStringFillerLen = (
  text: string,
  filler = '...',
  maxLength = 50
) => {
  let out = '';
  let lenMin = 2;
  let fillerMin = 0;
  if (filler && filler.length > 0) {
    lenMin += filler.length;
    fillerMin += filler.length;
  }
  if (text && text.length > 0 && maxLength > lenMin) {
    if (text.length > maxLength) {
      const rounded = Math.round((maxLength - fillerMin) / 2);
      out = text.slice(0, rounded) + filler + text.slice(rounded * -1);
    } else {
      out = text;
    }
  } else {
    out = 'N/A';
  }
  return out;
};

const formatStringFillerRegexLen = (
  text: string,
  filler: string,
  len: number
) => {
  const tmp = text.replace(/[^\w]/gi, '');
  return formatStringFillerLen(tmp, filler, len);
};

/**
 * Get item from LocalSotrage
 * @param itemName // Name of the item in the LocalStorage
 * @returns {Object | null} // Parsed object if exist or null
 */
const getItemFromStorage = (itemName: string) => {
  return localStorage.getItem(itemName)
    ? JSON.parse(localStorage.getItem(itemName) as string)
    : null;
};

/**
 * Set item in LocalSotrage
 * @param itemName // Name of the item in the LocalStorage
 * @param value // Item value
 */
const setItemStorage = (itemName: string, value: any) => {
  localStorage.setItem(itemName, JSON.stringify(value));
};

/**
 * Return array of items sorted by name
 * @param items // Array of items
 * @returns {Items[]} // Array of items
 */
const simpleSortItems = <T extends Item>(items: T[]): T[] => {
  return items.slice().sort((a: T, b: T) => (a.name > b.name ? 1 : -1));
};

/**
 * Generate random uuid
 * @returns {string} // uuid
 */
const generateUuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const roundValue = (value: number, decimals = 2) => {
  let factor = 1;
  for (let i = 0; i < decimals; i++) {
    factor *= 10;
  }
  return Math.round(value * factor) / factor;
};

const downloadBlob = (blob: Blob, name: string) => {
  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    return (window.navigator as any).msSaveOrOpenBlob(blob, name);
  }
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = blobUrl;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );
  setTimeout(() => {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
  }, 200);
};

const getOnlyAuthorizedMarkets = (markets: ItemLabel[]): ItemLabel[] => {
  const authStore = useAuthStore();

  let retVal: ItemLabel[] = [];

  if (authStore.user.fullAccess || authStore.isLocal) {
    retVal = markets;
  } else {
    markets.forEach(function (market: ItemLabel) {
      if (
        authStore.user.markets &&
        authStore.user.markets.includes(market.value)
      ) {
        retVal.push(market);
      }
    });
  }
  return retVal;
};

const getOnlyAuthorizedCategories = (
  categories: { value: Category; label: string }[]
) => {
  const authStore = useAuthStore();

  let retVal: { value: Category; label: string }[] = [];

  if (authStore.user.fullAccess || authStore.isLocal) {
    retVal = categories;
  } else {
    categories.forEach(function (category: { value: Category; label: string }) {
      if (
        authStore.user.categories &&
        authStore.user.categories.includes(category.value.id)
      ) {
        retVal.push(category);
      }
    });
  }
  return retVal;
};

const getOnlyAuthorizedCategoryStrings = <
  T extends ItemLabel | ItemLabelCategory
>(
  categories: T[]
): T[] => {
  const authStore = useAuthStore();

  let retVal: T[] = [];
  if (authStore.user.fullAccess || authStore.isLocal) {
    retVal = categories;
  } else {
    categories.forEach((category: T) => {
      if (
        authStore.user.categories &&
        authStore.user.categories.includes(category.value + '')
      ) {
        retVal.push(category);
      }
    });
  }
  return retVal;
};

const hasAuthorizationForMarket = (market: Item) => {
  const authStore = useAuthStore();

  if (authStore.user.fullAccess || authStore.isLocal) {
    return true;
  }
  if (authStore.user.markets && authStore.user.markets.includes(market.id)) {
    return true;
  }
  return false;
};

const valueToString = (
  value: number | string,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2
) => {
  if (!Number.isFinite(value)) return '';

  return value.toLocaleString(
    locale || process.env.VUE_APP_I18N_FALLBACK_LOCALE,
    {
      minimumFractionDigits,
      maximumFractionDigits,
    }
  );
};

const orderItems = (items: any[], itemProp = 'value', order = Order.DESC) => {
  if (items.length === 0 || !(itemProp in items[0])) return items;

  const [sort0, sort1] = order === Order.DESC ? [-1, 1] : [1, -1];

  return items
    .slice()
    .sort((a, b) => (a[itemProp] > b[itemProp] ? sort0 : sort1));
};

const arrayToObject = (array = [] as any[], key = 'name', value = 'value') => {
  return array.reduce((acc, val) => {
    key in val && (acc[val[key]] = val[value] || null);
    return acc;
  }, {});
};

const mdFilePath = (mdPath = 'md/app/use/', folder = '', routeName = '') => {
  switch (folder) {
    case SectionsIds.EMOLLIENT_MAESTRO:
      return `${mdPath}${folder.toLowerCase()}`;
    case 'ConsumerInsights':
      return `${mdPath}topclaims/${folder}`;
    case 'ConceptCollection':
      return `${mdPath}conceptcollectionoverview/${folder}`;
    case 'ConceptDetails':
      return `${mdPath}conceptdetails/ConceptCollectionOverview`;
    default:
      return `${mdPath}${routeName.toLowerCase()}/${folder}`;
  }
};

const dateToTimeAgo = (dateString: string) => {
  const date = moment(dateString, 'YYYY/MM/DD');
  return date.isValid() ? date.fromNow() : '';
};

const formatDuration = (duration: number) => {
  const minutes = Math.floor(duration / 60);
  const seconds = duration - minutes * 60;
  return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
};

const productCapacity = (capacity: string, unit = '') => {
  const capacityValue = capacity || '0';
  const capacityWithDot =
    capacityValue.indexOf('.') < 0 ? capacityValue + '.0' : capacityValue;
  const [intValue, capacityDecimals] = capacityWithDot.split('.');
  const capacityDecimalsFormatted = capacityDecimals.replace(/0+$/, '');
  const decimals = capacityDecimalsFormatted || '0';
  return `${intValue}.${decimals} ${unit}`;
};

const getKeyByValue = <T>(value: string, enumerable: T) => {
  const indexOfValue = Object.values(enumerable).indexOf(value as unknown as T);
  return Object.keys(enumerable)[indexOfValue];
};

const isHani = (value: string) => {
  const isHaniRegEx = /(\p{Script=Hani})+/gu;
  if (!value) {
    return false;
  }
  return value.match(isHaniRegEx);
};

const averageRange = (range: DataRange, defaultValue = null) => {
  if (range === null || !('minValue' in range) || !('maxValue' in range)) {
    return defaultValue;
  }
  if (range.minValue === null || range.maxValue === null) {
    return defaultValue;
  }
  return (range.maxValue + range.minValue) / 2;
};

const removeBlankSpacesFromSlash = (value: string) => {
  return value.replace(/\s*\/\s*/g, '/');
};

const getBase64FromArrayBuffer = (blob: ArrayBuffer) => {
  const base64String = btoa(
    new Uint8Array(blob).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ''
    )
  );

  return `data:image/jpeg;base64,${base64String}`;
};

const findSubmoduleById = (id: string, modules: NavigationModule[]) => {
  for (const module of modules) {
    const foundSubmodule = module.submoduleList.find(
      (submodule) => submodule.id === id
    );
    if (foundSubmodule) {
      return foundSubmodule;
    }
  }

  return undefined;
};

export {
  throttle,
  goToFedLogin,
  compare,
  mergeDeep,
  bindChartEvents,
  isIngredientBlacklisted,
  getEnvironment,
  getI18nText,
  getBreakPointColCount,
  getLetterLength,
  formatStringFillerLen,
  getItemFromStorage,
  setItemStorage,
  simpleSortItems,
  generateUuid,
  roundValue,
  downloadBlob,
  getOnlyAuthorizedMarkets,
  getOnlyAuthorizedCategories,
  getOnlyAuthorizedCategoryStrings,
  hasAuthorizationForMarket,
  valueToString,
  orderItems,
  arrayToObject,
  formatStringFillerRegexLen,
  mdFilePath,
  dateToTimeAgo,
  formatDuration,
  productCapacity,
  getKeyByValue,
  isHani,
  isNill,
  isArrayEqual,
  averageRange,
  removeBlankSpacesFromSlash,
  getBase64FromArrayBuffer,
  findSubmoduleById,
};
