import { EcoSystem, HomeSection } from '@/api/types';
import {
  HomeItemsIds,
  ICONS,
  SectionsIds,
  SectionGroupIds,
} from '@/typings/enums';
import { NavigationModule } from '@/typings/menu';
import theme from '@/lib/theme';

// Sections, used in HomePage.vue
export const baseSections: HomeSection[] = [
  {
    id: HomeItemsIds.FREE,
    title: 'free-you',
    description: '',
    premium: false,
    items: [
      {
        id: SectionsIds.COMPOSITIONS,
        title: 'ingredients-revealed',
        theme: 'green',
        description: '',
        expandColumn: true,
        showSlides: false,
        asset: 'ingredientsRevealed.jpg',
        imagePosition: '',
        icon: ICONS.COMPOSITIONS,
        iconRotation: 60,
        iconSize: 30,
        path: '/ir',
        color: '',
        goToText: '',
        slides: [
          {
            title: 'ingredients-revealed',
            description: 'ingredients-revealed',
            imagePosition: '60%, 50%',
          },
        ],
        requiredAccessible: SectionsIds.COMPOSITIONS,
      },
      {
        id: SectionsIds.CARE_360,
        title: 'care-360',
        theme: '',
        description: '',
        expandColumn: true,
        expandRow: false,
        showSlides: false,
        asset: 'care-360.jpg',
        imagePosition: '',
        icon: ICONS.CARE_360_ICON,
        iconRotation: 90,
        iconSize: 25,
        external: true,
        path: process.env.VUE_APP_CARE360_URL,
        color: '',
        goToText: '',
        slides: [
          {
            title: 'care-360',
            description: 'care-360',
            imagePosition: '60%, 50%',
          },
        ],
      },
      {
        id: SectionsIds.LEARN_MORE,
        title: 'learn-more',
        theme: '',
        description: '',
        expandColumn: false,
        expandRow: false,
        showSlides: false,
        asset: 'learnMore.jpg',
        imagePosition: '',
        icon: ICONS.VIDEO_MODULE_ICON,
        iconRotation: 60,
        iconSize: 25,
        path: '/lm',
        color: '',
        goToText: '',
        slides: [
          {
            title: 'learn-more',
            description: 'learn-more',
            imagePosition: '50%, 50%',
          },
        ],
      },
      {
        id: SectionsIds.EMOLLIENT_JOCKEY,
        title: 'emollient-jockey',
        theme: 'green',
        description: '',
        expandColumn: false,
        showSlides: false,
        asset: 'emollientJockey.jpg',
        imagePosition: '',
        icon: ICONS.EMOLLIENT_JOCKEY_ICON,
        iconRotation: 130,
        iconSize: 25,
        path: '/ej',
        color: '',
        goToText: '',
        slides: [
          {
            title: 'emollient-jockey',
            description: 'emollient-jockey',
            imagePosition: '50%, 50%',
          },
        ],
      },
      {
        id: SectionsIds.SURFACTANT_NAVIGATOR,
        title: 'surfactant-navigator',
        theme: 'green',
        description: '',
        expandColumn: true,
        showSlides: false,
        asset: 'surfactantNavigator.jpg',
        imagePosition: '',
        icon: ICONS.SURFACTANT_NAVIGATOR,
        iconRotation: -40,
        iconSize: 25,
        path: '/sn',
        color: '',
        goToText: '',
        slides: [
          {
            title: 'surfactant-navigator',
            description: 'surfactant-navigator',
            imagePosition: '46%, 50%',
          },
        ],
        requiredAccessible: SectionsIds.SURFACTANT_NAVIGATOR,
      },
      {
        id: SectionsIds.INGREDIENT_SELECTION,
        title: 'ingredient-selection',
        theme: 'green-light-2',
        description: '',
        asset: 'ingredientSelection.jpg',
        imagePosition: '50%, 50%',
        icon: ICONS.INGREDIENT_SELECTION_ICON,
        iconRotation: -30,
        iconSize: 25,
        path: '/is',
        color: '',
        goToText: '',
        showSlides: false,
        slides: [
          {
            title: 'ingredient-selection',
            asset: 'ingredientSelection.jpg',
            description: 'ingredient-selection',
            imagePosition: '50%, 50%',
          },
        ],
      },
      {
        id: SectionsIds.SFA,
        title: 'sfa',
        theme: 'blue-light-2',
        description: '',
        asset: 'sfa.jpg',
        imagePosition: '50%, 50%',
        icon: ICONS.SFA_ICON,
        iconRotation: -125,
        iconSize: 25,
        path: '',
        external: true,
        color: '',
        goToText: '',
        showSlides: false,
        slides: [
          {
            title: 'sfa',
            asset: 'sfa.jpg',
            description: 'sfa',
            imagePosition: '68%, 50%',
          },
        ],
      },
      {
        id: SectionsIds.CONCEPT_COLLECTION,
        title: 'concept-collection',
        theme: 'orange-light-1',
        asset: 'conceptCollection.jpg',
        imagePosition: '',
        icon: ICONS.CONCEPT_COLLECTION_ICON,
        iconRotation: -110,
        iconSize: 25,
        description: '',
        showSlides: false,
        path: '/cc',
        color: '',
        goToText: '',
        slides: [
          {
            title: 'concept-collection',
            description: 'concept-collection',
            imagePosition: '50%, 50%',
          },
        ],
      },
      {
        id: SectionsIds.TREND,
        title: 'trends',
        theme: 'green',
        asset: 'trends.jpg',
        imagePosition: '',
        icon: ICONS.TREND_ICON,
        iconRotation: -80,
        iconSize: 25,
        description: '',
        showSlides: false,
        path: '/trends',
        color: '',
        goToText: '',
        slides: [
          {
            title: 'trends',
            description: 'trends',
            imagePosition: '25%, 50%',
          },
        ],
      },
      {
        id: SectionsIds.FORMULATION_DESIGN,
        title: 'formulation-design',
        description: '',
        asset: '',
        imagePosition: '',
        icon: ICONS.FORMULATION_DESIGN_ICON,
        iconRotation: -120,
        iconSize: 25,
        theme: 'orange-light-1',
        showSlides: false,
        path: '/fd',
        color: '',
        goToText: '',
        slides: [
          {
            title: 'formulation-design',
            asset: 'formulationDesign.jpg',
            description: 'formulation-design',
            imagePosition: '55%, 50%',
          },
        ],
      },
    ],
  },
  {
    id: HomeItemsIds.MORE,
    title: 'now-available',
    description: '',
    premium: true,
    items: [
      {
        id: SectionsIds.EMOLLIENT_MAESTRO,
        title: 'emollient-maestro',
        theme: 'green',
        description: '',
        expandColumn: true,
        showSlides: false,
        asset: 'emollientMaestro.jpg',
        imagePosition: '',
        icon: ICONS.EMOLLIENT_MAESTRO_ICON,
        iconRotation: -40,
        iconSize: 30,
        path: '/em/easy',
        color: '',
        goToText: '',
        slides: [
          {
            title: 'emollient-maestro',
            description: 'emollient-maestro',
            imagePosition: '50%, 50%',
          },
        ],
        requiredAccessible: SectionsIds.EMOLLIENT_MAESTRO,
      },
    ],
  },
  {
    id: HomeItemsIds.HOWTO,
    title: 'how-to',
    description: 'market-insight',
    premium: true,
    items: [
      {
        id: SectionsIds.BRAND_ANALYTICS,
        title: 'brand-analytics',
        asset: '',
        imagePosition: '',
        icon: ICONS.BRAND_ANALYTICS_ICON,
        iconRotation: 60,
        iconSize: 25,
        theme: '',
        description: '',
        showSlides: false,
        path: '/ba',
        color: '',
        goToText: '',
        slides: [
          {
            title: 'brand-analytics',
            asset: 'brandAnalytics.jpg',
            description: 'brand-analytics',
            imagePosition: '50%, 50%',
          },
        ],
      },
      {
        id: SectionsIds.CONSUMER_PRODUCTS,
        title: 'consumer-products',
        asset: 'consumerProducts.jpg',
        imagePosition: '',
        icon: ICONS.CONSUMER_PRODUCTS_ICON,
        iconRotation: 140,
        iconSize: 25,
        showSlides: false,
        theme: '',
        description: '',
        path: '/cp',
        color: '',
        goToText: '',
        slides: [
          {
            title: 'consumer-products',
            description: 'consumer-products',
            imagePosition: '20%, 50%',
          },
        ],
      },
      {
        id: SectionsIds.CONSUMER_INSIGHTS,
        title: 'consumer-insights',
        theme: 'blue-light-2',
        asset: 'consumerInsights.jpg',
        imagePosition: '80%, 50%',
        icon: ICONS.CONSUMER_INSIGHTS_ICON,
        iconRotation: -40,
        iconSize: 25,
        expandColumn: false,
        showSlides: false,
        description: '',
        path: '/ci',
        color: '',
        goToText: '',
        slides: [
          {
            title: 'consumer-insights',
            description: 'consumer-insights',
            imagePosition: '80%, 50%',
          },
        ],
      },
      {
        id: SectionsIds.MARKET_INSIGHTS,
        title: 'market-insights',
        theme: 'blue-light-2',
        asset: '',
        imagePosition: '',
        icon: ICONS.MARKET_INSIGHTS_ICON,
        iconRotation: -110,
        iconSize: 25,
        description: '',
        showSlides: false,
        path: '/ci-mo',
        color: '',
        goToText: '',
        slides: [
          {
            title: 'market-insights',
            asset: 'marketInsights.jpg',
            description: 'market-insights',
            imagePosition: '45%, 50%',
          },
        ],
      },
    ],
  },
  {
    id: HomeItemsIds.HOWTO,
    title: 'how-to',
    description: 'products-customers-love',
    premium: true,
    items: [
      {
        id: SectionsIds.MY_DLITE,
        title: 'my-dlite',
        theme: 'gray',
        description: '',
        asset: 'myDlite.jpg',
        imagePosition: '10%, 50%',
        icon: ICONS.MYDLITE_ICON,
        iconRotation: 130,
        iconSize: 25,
        expandColumn: true,
        showSlides: false,
        path: '/md',
        color: '',
        goToText: '',
        slides: [
          {
            title: 'my-dlite',
            description: 'my-dlite',
            imagePosition: '10%, 50%',
          },
        ],
      },
    ],
  },
];

export const apps: EcoSystem[] = [
  {
    id: SectionsIds.LEARN_MORE,
    href: '/lm',
    text: 'app.header.learn-more',
    subsystem: [],
    icon: 'icon-learn-more default',
  },
  {
    id: SectionsIds.CARE_STORE,
    href: '',
    text: 'app.header.carestore',
    subsystem: [],
    icon: 'icon-carestore default',
    newPage: true,
  },
  {
    id: SectionsIds.EMOLLIENT_JOCKEY,
    href: '/ej',
    text: 'app.header.emollient-jockey',
    subsystem: [],
    icon: 'icon-emollient-jockey default',
  },
  {
    id: SectionsIds.EMOLLIENT_MAESTRO,
    href: '/em',
    text: 'app.header.emollient-maestro',
    subsystem: [],
    icon: 'icon-emollient-maestro default',
  },
  {
    id: SectionsIds.SURFACTANT_NAVIGATOR,
    href: '/sn',
    text: 'app.header.surfactant-navigator',
    subsystem: [],
    icon: 'icon-surfactant-navigator default',
  },
  {
    id: SectionsIds.BRAND_ANALYTICS,
    href: '/ba',
    text: 'app.header.brand-analytics',
    subsystem: [],
    icon: 'icon-brand default',
  },
  {
    id: SectionsIds.CONSUMER_PRODUCTS,
    href: '/cp',
    text: 'app.header.consumer-products',
    subsystem: [],
    icon: 'icon-product default',
  },
  {
    id: SectionsIds.CONSUMER_INSIGHTS,
    href: '/ci',
    text: 'app.header.top-ranking',
    subsystem: [],
    icon: 'icon-insight default',
  },
  {
    id: SectionsIds.MARKET_INSIGHTS,
    href: '/ci-mo',
    text: 'app.header.market-overview',
    subsystem: [],
    icon: 'icon-market default',
  },
  {
    id: SectionsIds.COMPOSITIONS,
    href: '/ir',
    text: 'app.header.ingredients-revealed',
    subsystem: [],
    icon: 'icon-ingredients-revealed default',
  },
  {
    id: SectionsIds.CONCEPT_COLLECTION,
    href: '/cc',
    text: 'app.header.concept-collection',
    subsystem: [],
    icon: 'icon-concept default',
  },
  {
    id: SectionsIds.TREND,
    href: '/trends',
    text: 'app.header.trends',
    subsystem: [],
    icon: 'icon-trends default',
  },
  {
    id: SectionsIds.FORMULATION_DESIGN,
    href: '/fd',
    text: 'app.header.formulation-design',
    subsystem: [],
    icon: 'icon-formulation default',
  },
  {
    id: SectionsIds.INGREDIENT_SELECTION + ',' + SectionsIds.BASF_PRODUCTS,
    href: '/is',
    text: 'app.header.ingredient-selection',
    subsystem: [],
    icon: 'icon-ingredient default',
    relatedSection: '/bp',
  },
  {
    id: SectionsIds.MY_DLITE,
    href: '/md',
    text: 'app.header.my-dlite',
    subsystem: [],
    icon: 'icon-mydlite default',
  },
];

// Drawer apps grouped for the new Sidebar design, used in DliteHeader.vue
export const navigationModules: NavigationModule[] = [
  {
    id: SectionGroupIds.PRODUCT_DEVELOPMENT,
    icon: 'icon-product-development default',
    text: 'app.header.product-development',
    submoduleList: [
      {
        id: SectionsIds.EMOLLIENT_JOCKEY,
        href: '/ej',
        text: 'app.header.emollient-jockey',
        icon: 'icon-emollient-jockey default',
        image: 'emollient-img.jpg',
        altText: '',
        color: theme[SectionsIds.EMOLLIENT_JOCKEY].hexColor,
        description: 'app.page.home.sections.emollient-jockey',
      },
      {
        id: SectionsIds.EMOLLIENT_MAESTRO,
        href: '/em',
        text: 'app.header.emollient-maestro',
        icon: 'icon-emollient-maestro default',
        image: 'maestro-img.jpg',
        altText: '',
        color: theme[SectionsIds.EMOLLIENT_MAESTRO].hexColor,
        description: 'app.page.home.sections.emollient-maestro',
        requiredAccessible: SectionsIds.EMOLLIENT_MAESTRO,
      },
      {
        id: SectionsIds.FORMULATION_DESIGN,
        href: '/fd',
        text: 'app.header.formulation-solutions',
        icon: 'icon-formulation default',
        image: 'formulationSolutions-img.jpg',
        altText: '',
        color: theme[SectionsIds.FORMULATION_DESIGN].hexColor,
        description: 'app.page.home.sections.formulation-design',
        requiredAccessible: SectionsIds.FORMULATION_DESIGN,
      },
      {
        id: SectionsIds.COMPOSITIONS,
        href: '/ir',
        text: 'app.header.ingredients-revealed',
        icon: 'icon-ingredients-revealed default',
        image: 'maestro-img.jpg',
        altText: '',
        color: theme[SectionsIds.COMPOSITIONS].hexColor,
        description: 'app.page.home.sections.ingredients-revealed',
      },
      {
        id: SectionsIds.INGREDIENT_SELECTION,
        href: '/is',
        text: 'app.header.ingredient-selection',
        icon: 'icon-ingredient default',
        image: 'insights-img.jpg',
        altText: '',
        color: theme[SectionsIds.INGREDIENT_SELECTION].hexColor,
        relatedSection: '/bp',
        description: 'app.page.home.sections.ingredient-selection',
      },
      {
        id: SectionsIds.SFA,
        href: 'https://dev.qritos.basf.com/welcome',
        text: 'app.header.sfa',
        icon: ICONS.SFA_ICON,
        image: 'sfa.jpg',
        altText: '',
        color: theme[SectionsIds.SFA].hexColor,
        description: 'app.page.home.sections.sfa',
        newPage: true,
      },
      {
        id: SectionsIds.SURFACTANT_NAVIGATOR,
        href: '/sn',
        text: 'app.header.surfactant-navigator',
        icon: 'icon-surfactant-navigator default',
        image: 'insights-img.jpg',
        altText: '',
        color: theme[SectionsIds.SURFACTANT_NAVIGATOR].hexColor,
        description: 'app.page.home.sections.surfactant-navigator',
        requiredAccessible: SectionsIds.SURFACTANT_NAVIGATOR,
      },
    ],
  },
  {
    id: SectionGroupIds.MARKET_INTELLIGENCE,
    icon: 'icon-market-intelligence default',
    text: 'app.header.market-intelligence',
    submoduleList: [
      {
        id: SectionsIds.BRAND_ANALYTICS,
        href: '/ba',
        text: 'app.header.brand-analytics',
        icon: 'icon-brand default',
        image: 'brandAnalitics-img.jpg',
        altText: '',
        color: theme[SectionsIds.BRAND_ANALYTICS].hexColor,
        description: 'app.page.home.sections.brand-analytics',
        requiredAccessible: SectionsIds.BRAND_ANALYTICS,
      },
      {
        id: SectionsIds.CONCEPT_COLLECTION,
        href: '/cc',
        text: 'app.header.concept-collection',
        icon: 'icon-concept default',
        image: 'conceptCollection-img.jpg',
        altText: '',
        color: theme[SectionsIds.CONCEPT_COLLECTION].hexColor,
        description: 'app.page.home.sections.concept-collection',
        requiredAccessible: SectionsIds.CONCEPT_COLLECTION,
      },
      {
        id: SectionsIds.CONSUMER_INSIGHTS,
        href: '/ci',
        text: 'app.header.top-ranking',
        icon: 'icon-insight default',
        image: 'insights-img.jpg',
        altText: '',
        color: theme[SectionsIds.CONSUMER_INSIGHTS].hexColor,
        description: 'app.page.home.sections.consumer-insights',
        requiredAccessible: SectionsIds.CONSUMER_INSIGHTS,
      },
      {
        id: SectionsIds.CONSUMER_PRODUCTS,
        href: '/cp',
        text: 'app.header.consumer-products',
        icon: 'icon-product default',
        image: 'insights-img.jpg',
        altText: '',
        color: theme[SectionsIds.CONSUMER_PRODUCTS].hexColor,
        description: 'app.page.home.sections.consumer-products',
        requiredAccessible: SectionsIds.CONSUMER_PRODUCTS,
      },
      {
        id: SectionsIds.MARKET_INSIGHTS,
        href: '/ci-mo',
        text: 'app.header.market-overview',
        icon: 'icon-market default',
        image: 'insights-img.jpg',
        altText: '',
        color: theme[SectionsIds.MARKET_INSIGHTS].hexColor,
        description: 'app.page.home.sections.market-insights',
        requiredAccessible: SectionsIds.MARKET_INSIGHTS,
      },
      {
        id: SectionsIds.TREND,
        href: '/trends',
        text: 'app.header.trends',
        icon: 'icon-trends default',
        image: 'insights-img.jpg',
        altText: '',
        color: theme[SectionsIds.TREND].hexColor,
        description: 'app.page.home.sections.trends',
        requiredAccessible: SectionsIds.TREND,
      },
    ],
  },
  {
    id: SectionGroupIds.LEARNING_AREA,
    icon: 'icon-learning-area default',
    text: 'app.header.learning-area',
    submoduleList: [
      {
        id: SectionsIds.LEARN_MORE,
        href: '/lm',
        text: 'app.header.dlite-academy',
        icon: 'icon-trends default',
        image: 'dliteAcademy-img.jpg',
        altText: '',
        color: theme[SectionsIds.LEARN_MORE].hexColor,
        description: 'app.page.home.sections.dlite-academy',
        requiredAccessible: SectionsIds.LEARN_MORE,
      },
      // OUR SOLUTIONS
    ],
  },
  {
    id: SectionGroupIds.PERSONAL_AREA,
    icon: 'icon-personal-area default',
    text: 'app.header.personal-area',
    disabled: true,
    submoduleList: [
      {
        id: SectionsIds.MY_DLITE,
        href: '/md',
        text: 'app.header.my-dlite',
        icon: 'icon-mydlite default',
        image: 'mydlite-img.jpg',
        altText: '',
        color: theme[SectionsIds.MY_DLITE].hexColor,
        description: 'app.page.home.sections.my-dlite',
        fixed: true,
      },
      // MY_SETTINGS
    ],
  },
  {
    id: SectionGroupIds.DISCOVER_MORE,
    icon: 'icon-discover-more default',
    text: 'app.header.discover-more',
    submoduleList: [
      {
        id: SectionsIds.CARE_360,
        href: process.env.VUE_APP_CARE360_URL,
        text: 'app.header.care-360',
        icon: ICONS.CARE_360_ICON,
        image: 'care360-img.jpg',
        altText: '',
        color: theme[SectionsIds.CARE_360].hexColor,
        description: 'app.page.home.sections.care-360',
        newPage: true,
      },
      {
        id: SectionsIds.CARE_STORE,
        href: '',
        text: 'app.header.carestore',
        icon: 'icon-carestore default',
        image: 'care360-img.jpg',
        altText: '',
        color: theme[SectionsIds.CARE_STORE].hexColor,
        description: 'app.page.home.sections.careStore',
        newPage: true,
      },
    ],
  },
];
