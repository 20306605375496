<template>
  <dlite-layout id="main" :class="`theme-${theme}`">
    <transition :name="transitionName" mode="out-in">
      <router-view :key="pathKey"></router-view>
    </transition>

    <transition name="fade">
      <div
        v-if="showScrollTop"
        class="scroll-top"
        :style="scrollTopBottomPX"
        @click="scrollToTop"
      >
        <font-awesome-icon icon="chevron-down" size="lg" class="themed-color" />
      </div>
    </transition>

    <v-snackbar
      v-model="showNotification"
      right
      class="notification-wrapper"
      :color="notificationType"
    >
      <v-icon>{{ notificationIcon }}</v-icon>
      {{ notificationText }}

      <template v-slot:action="{ attrs }">
        <v-icon v-bind="attrs" @click="showNotification = false">
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
  </dlite-layout>
</template>

<script setup lang="ts">
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { computed, onMounted, ref } from 'vue';
import { useAppStore } from '@/stores/app';
import { useRouter } from '@/composables/useRouter';
import { useNotifications } from '@/composables/useNotifications';
import DliteLayout from '@/layouts/DliteLayout.vue';
import { useWindowScroll } from '@vueuse/core';
import { useResponsive } from '@/composables/useResponsive';
import { SectionsIds } from '@/typings/enums';

const appStore = useAppStore();

const { y: windowScroll } = useWindowScroll();
const { screenHeight } = useResponsive();
const { showNotification, notificationText, notificationType } =
  useNotifications();

const scrollLimit = 200;
const scrollToTopBottomMargin = 30;
const scrollToTopBottomLimit = 75 + scrollToTopBottomMargin;

const { route: currentRoute } = useRouter();

const scrollTopBottomPX = computed(() => {
  const scrollY =
    document.documentElement.scrollHeight -
    windowScroll.value -
    screenHeight.value +
    scrollToTopBottomLimit;
  if (scrollY > 0) {
    return `bottom: ${scrollToTopBottomMargin}px`;
  }
  return `bottom: ${scrollToTopBottomMargin - scrollY}px`;
});

const transitionName = computed(() => {
  return appStore.transitionName;
});

const scrollToTop = () => {
  window.scroll({
    top: 0,
    behavior: 'smooth',
  });
};

const theme = computed(() => {
  return appStore.themeColor;
});

const showScrollTop = computed(() => {
  return windowScroll.value > scrollLimit;
});

const pathKey = computed(() => {
  if (!currentRoute.value) return;

  const isMaestro = currentRoute.value.matched.some(
    (route) => route.name === SectionsIds.EMOLLIENT_MAESTRO
  );

  if (isMaestro) return `${appStore.locale}`;

  return `${currentRoute.value.fullPath}-${appStore.locale}`;
});

const notificationIcon = computed(() => {
  if (notificationType.value === 'success') {
    return 'mdi-check';
  } else if (notificationType.value === 'error') {
    return 'mdi-alert-circle';
  } else if (notificationType.value === 'warning') {
    return 'mdi-alert';
  } else if (notificationType.value === 'info') {
    return 'mdi-information';
  }

  return '';
});

// PWA
const showOfflineMessage = ref(false);
const showInstallPWAMessage = ref(false);
let deferredPrompt: any;

const updateWindow = async () => {
  const cacheNames = await window.caches.keys();
  await Promise.all(
    cacheNames.map((cacheName) => window.caches.delete(cacheName))
  );
  window.location.reload();
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const installPWA = async () => {
  deferredPrompt.prompt();
  const { outcome } = await deferredPrompt.userChoice;
  // The deferredPrompt can only be used once.
  deferredPrompt = null;
  // Act on the user's choice
  if (outcome === 'accepted') {
    // Track: User accepted the install prompt
  } else if (outcome === 'dismissed') {
    // Track: User dismissed the install prompt
  }
};

onMounted(() => {
  window.addEventListener('message', (event) => {
    if (event.data?.msg === 'SW_UPDATE') {
      // TODO: Build a notification system to indicate the user that there are new updates
      updateWindow();
    }
  });
  window.addEventListener('beforeinstallprompt', (event) => {
    event.preventDefault();
    deferredPrompt = event;
    showInstallPWAMessage.value = true;
  });
  window.addEventListener('offline', () => {
    showOfflineMessage.value = true;
  });
  window.addEventListener('online', () => {
    showOfflineMessage.value = false;
  });
});
</script>

<style lang="scss">
#main {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
  min-height: 740px;
}

.container {
  padding: 0 !important;
}

.row {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.scroll-top {
  position: fixed;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 0 5px -2px $gray;
  width: 50px;
  height: 50px;
  right: 30px;
  bottom: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 40;

  .fa-chevron-down {
    transform: rotate(180deg);
  }
}

.v-input input {
  font-size: 0.87rem !important;
}

.v-toolbar__content {
  padding: 0 !important;
}

.subtitle-3 {
  font-size: 0.87rem !important;
}

.subtitle-4 {
  font-size: 0.6rem !important;
}

.pageHeader {
  font-size: 1.25rem !important;
}

.applicationCard {
  padding: 20px !important;
  background-color: #ffffff !important;
}

.smallApplicationCard {
  padding: 15px 20px 10px 20px !important;
  background-color: #ffffff !important;
}

.v-sheet,
.v-card,
.v-dialog,
.v-btn,
.v-menu__content,
.v-text-field,
.v-tooltip__content {
  border-radius: 0 !important;
}

.v-autocomplete:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: $basf-color-gray !important;
}

.noBorder fieldset {
  border-color: transparent !important;
}

.v-input--is-focused fieldset {
  border-color: currentColor !important;
}

.noPadding {
  padding: 0 !important;
}

.noMargin {
  margin: 0 !important;
}

.noVerticalPadding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.noHorizontalPadding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.noHorizontalMargin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.rowDivider {
  padding: 5px 0 !important;
}

.categoryBox {
  padding: 4px 20px !important;
  height: 26px !important;
  line-height: 16px;
}
.productNameBox {
  padding: 2px 25px 5px 0 !important;
}

.sourceInfoBox {
  padding: 2px 25px 5px 25px !important;
}

.subCategoryBox {
  border: 2px solid var(--base-lightest);
  padding-left: 5px !important;
}

.highlightedBox {
  border: 1px solid var(--base-lightest);
  color: var(--base-light);
  padding: 3px 15px;
}

.lightGreyBox {
  border: 1px solid #f0f0f0;
  padding: 3px 15px;
}

.filled-box-light {
  border: 2px solid;
  background: var(--base-lightest);
  border-color: var(--base-lightest);
  color: var(--base-light);
  text-align: center;
}

.compareBoxDisabled {
  background-color: #f0f0f0 !important;
  color: #ffffff;
  padding: 2px 10px;
}

.compareBoxSelected {
  background-color: #7c7c7c !important;
  color: #ffffff;
  padding: 2px 10px;
  cursor: pointer;
}

.compareBox {
  color: #ffffff;
  padding: 2px 10px;
}

.unfilled-box {
  border: 2px solid var(--base-light);
  padding-left: 5px !important;
}

.filled-box {
  border: 2px solid var(--base-light);
  background: var(--base-light);
  color: #ffffff;
  text-align: center;
}

.greyBox {
  border: 2px solid #f0f0f0;
  padding-left: 5px !important;
}

.greyBoxFilled {
  border: 2px solid #f0f0f0;
  background: #f0f0f0;
  text-align: center;
}

.v-divider {
  border-color: #f0f0f0 !important;
}

.normalTextColor {
  color: rgba(0, 0, 0, 0.87) !important;
}

.noLinkColor {
  text-decoration: none !important;
  color: inherit !important;
}

.lightGreyButton {
  background-color: #f5f5f5 !important;
  text-transform: none !important;
}

.redButton {
  background-color: #c50022 !important;
  text-transform: none !important;
}

.no-transform {
  text-transform: none !important;
}

.orangeButton {
  background-color: #f39500 !important;
  text-transform: none !important;
}

.greyButton {
  background-color: #7c7c7c !important;
  text-transform: none !important;
}

.whiteIcon {
  color: #ffffff !important;
  opacity: 100 !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rangeSlideBackground {
  background-image: url('./assets/range-stick.png');
  background-repeat: repeat-y;
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}

.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.47s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.zoom-leave-active {
  animation-direction: reverse;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.1s ease;
}

.slide-left-enter,
.slide-left-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.slide-right-enter,
.slide-right-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

.v-icon.leaf-green-medium {
  height: 20px;
  width: 20px;
  background-image: url('./assets/leaf.svg');
  filter: invert(57%) sepia(34%) saturate(898%) hue-rotate(47deg)
    brightness(94%) contrast(95%);
  background-size: contain;
  background-repeat: no-repeat;
}

.v-icon.leaf-outline-green-medium {
  height: 20px;
  width: 20px;
  background-image: url('./assets/leaf-outline.svg');
  filter: invert(57%) sepia(34%) saturate(898%) hue-rotate(47deg)
    brightness(94%) contrast(95%);
  background-size: contain;
  background-repeat: no-repeat;
}

.v-icon.leaf-red-small {
  height: 16px;
  width: 16px;
  background-image: url('./assets/leaf.svg');
  filter: invert(11%) sepia(84%) saturate(6860%) hue-rotate(345deg)
    brightness(76%) contrast(105%);
  background-size: contain;
  background-repeat: no-repeat;
}

.v-icon.leaf-outline-red-small {
  height: 16px;
  width: 16px;
  background-image: url('./assets/leaf-outline.svg');
  filter: invert(11%) sepia(84%) saturate(6860%) hue-rotate(345deg)
    brightness(76%) contrast(105%);
  background-size: contain;
  background-repeat: no-repeat;
}

.v-icon.leaf-grey-small {
  height: 16px;
  width: 16px;
  background-image: url('./assets/leaf.svg');
  filter: invert(37%) sepia(7%) saturate(0%) hue-rotate(162deg) brightness(91%)
    contrast(102%);
  background-size: contain;
  background-repeat: no-repeat;
}

.v-icon.leaf-outline-grey-small {
  height: 16px;
  width: 16px;
  background-image: url('./assets/leaf-outline.svg');
  filter: invert(37%) sepia(7%) saturate(0%) hue-rotate(162deg) brightness(91%)
    contrast(102%);
  background-size: contain;
  background-repeat: no-repeat;
}

.v-icon.leaf-green-medium {
  height: 20px;
  width: 20px;
  background-image: url('./assets/leaf.svg');
  filter: invert(57%) sepia(34%) saturate(898%) hue-rotate(47deg)
    brightness(94%) contrast(95%);
  background-size: contain;
  background-repeat: no-repeat;
}

.v-icon.leaf-outline-green-medium {
  height: 20px;
  width: 20px;
  background-image: url('./assets/leaf-outline.svg');
  filter: invert(57%) sepia(34%) saturate(898%) hue-rotate(47deg)
    brightness(94%) contrast(95%);
  background-size: contain;
  background-repeat: no-repeat;
}

.v-icon.leaf-themed-small {
  height: 16px;
  width: 16px;
  background-image: url('./assets/leaf.svg');
  background-size: contain;
  background-repeat: no-repeat;
}

.v-icon.leaf-outline-themed-small {
  height: 16px;
  width: 16px;
  background-image: url('./assets/leaf-outline.svg');
  background-size: contain;
  background-repeat: no-repeat;
}

.fixedCompareFooter {
  z-index: 11;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  box-shadow: 0 -1px rgba(0, 0, 0, 0.1);
}

.shadowBorderBottom {
  -webkit-box-shadow: 0 4px 6px -7px #222;
  -moz-box-shadow: 0 4px 6px -7px #222;
  box-shadow: 0 4px 6px -14px #222;
}

@media only screen and (max-width: 1180px) {
  .header-breadcrumb {
    display: none !important;
  }

  .compareBoxDisabled {
    padding: 2px 2px;
  }

  .compareBoxSelected {
    padding: 2px 2px;
  }

  .compareBox {
    padding: 2px 2px !important;
  }
}
@media only screen and (max-width: 900px) {
  .categoryBox {
    padding: 4px 0 !important;
  }
  .productNameBox {
    padding: 2px 5px 5px 0 !important;
  }

  .sourceInfoBox {
    padding: 2px 0 5px 0 !important;
  }
  .applicationCard {
    min-width: 450px;
  }
}

@media only screen and (max-width: $breakpoint-xs) {
  .applicationCard {
    min-width: 300px;
  }
  .noHorizontalPaddingMobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.full-width {
  width: 100%;
}

.menu-header {
  background: white;
  height: 100%;
  padding: 0 0 8px 0 !important;
}
.menu-header-list-item {
  min-height: auto !important;
  padding-bottom: 4px !important;
  padding-top: 4px !important;
  border-bottom: 1px solid #f5f5f5;
}
.menu-header-list-item:hover:not(.menu-group-title) {
  border-left: 8px solid $basf-color-gray;
  transition: border-width 0.2s linear;
}
.menu-header-list-item.active:not(.menu-group-title) {
  border-left: 8px solid $basf-color-gray;
}
.menu-header-list-item.menu-group-title {
  background-color: #f2f2f2;
  padding: 10px 0 10px 0 !important;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0;
  .favorite-apps {
    color: #f39500;
  }
  .free-you {
    color: #c50022;
  }
  .dlite-bundle {
    color: #21a0d2;
  }
  .other-apps {
    color: #00793a;
  }
}

.menu-header-item {
  margin-left: 5px;
  padding: 10px 5px 10px 5px;
}
.header-dlite {
  padding: 10px 0 10px 0;
  top: 21px;
  left: 78px;
  width: 67px;
  height: 37px;
  text-align: left;
  font: normal normal bold 30px/26px Roboto;
  letter-spacing: 0;
  opacity: 1;
  text-decoration: none;
}
.header-line-page {
  width: 97%;
  height: 2px;
  float: left;
  margin: 0 20px 0 20px;
  text-align: center;
}
.header-line-page.red-page {
  background-color: #c50022;
}
.header-nav-menu-item {
  float: left;
  padding: 10px;
  cursor: pointer;
}
.header-breadcrumb {
  .v-breadcrumbs__item {
    color: black;
  }
  .v-breadcrumbs__divider {
    padding: 0 5px 0 5px !important;
  }
}
.header-side-menu {
  height: 100%;
  top: 72px !important;
  left: 0 !important;
  border-top: 2px solid black;
}

.menu-header-list-item.active:not(.menu-group-title) {
  &.type-icon-brand {
    border-left: 8px solid $red;
    .normalTextColor {
      color: $red !important;
    }
  }
  &.type-icon-product {
    border-left: 8px solid $green;
    .normalTextColor {
      color: $green !important;
    }
  }
  &.type-icon-insight {
    border-left: 8px solid $blue_light_2;
    .normalTextColor {
      color: $blue_light_2 !important;
    }
  }
  &.type-icon-market {
    border-left: 8px solid $orange_light_1;
    .normalTextColor {
      color: $orange_light_1 !important;
    }
  }
  &.type-icon-concept {
    border-left: 8px solid $blue;
    .normalTextColor {
      color: $blue !important;
    }
  }
  &.type-icon-formulation {
    border-left: 8px solid $orange_light_1;
    .normalTextColor {
      color: $orange_light_1 !important;
    }
  }
  &.type-icon-ingredient {
    border-left: 8px solid $orange;
    .normalTextColor {
      color: $orange !important;
    }
  }
  &.type-icon-house {
    border-left: 8px solid $gray;
    .normalTextColor {
      color: $gray !important;
    }
  }
  &.type-icon-emollient-jockey {
    border-left: 8px solid $green;
    .normalTextColor {
      color: $green !important;
    }
  }
}
.background-gray {
  background-color: $background-gray;
}

.listRow {
  &__image {
    text-align: center;
    background-color: $background-gray;
    &__m1 {
      svg {
        height: 50px !important;
        width: 50px !important;
      }
    }
    svg {
      height: 55px !important;
      width: 55px !important;
    }
  }
  &__small-image {
    text-align: center;
    background-color: $background-gray;
    svg {
      height: 35px !important;
      width: 35px !important;
    }
  }
  .v-avatar {
    position: relative !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
}
</style>
@/stores/favoriteModules
