/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { defineStore } from 'pinia';
import {
  BrandAnalyticResponse,
  BrandAnalyticReq,
  ParamsOptions,
  BrandAnalyticsAdvancedSearchReq,
  BrandAnalyticsAdvancedSearchPref,
} from '@/api/types';
import {
  fetchBrandAnalyticsAdvancedBrand,
  fetchBrandAnalyticsAdvancedMap,
  fetchBrandAnalyticSearch,
  fetchBrandAnalyticsLifestyle,
  fetchBrandDetail,
} from '@/api';
import { BrandAnalyticsTabs } from '@/typings/brands';

interface State {
  brandAnalyticData: BrandAnalyticResponse | null;
  brandAnalyticSearch: string | null;
  brandAnalyticSort: string | null;
  brandAnalyticPage: number | null;
  showAdvancedFilter: boolean;
  showAdvancedActiveFilter: boolean;
  brandAnalyticAdvancedPref: BrandAnalyticsAdvancedSearchPref;
  backupBrandAnalyticAdvancedPref: BrandAnalyticsAdvancedSearchPref;
  noOfResults: number | null;
  openedFilters: string[];
  fetchedFiltersData: boolean;
  currentTab: string;
  scrollPosition: number;
}

const emptyBrandAnalyticAdvancedPref = {
  params: [],
  brandSelection: [],
  priceSelection: [],
  lifestyleSelection: [],
  categorySelection: [],
  subCategorySelection: [],
  dateSelection: [],
  marketSelection: '',
  companySelection: [],
};

// Returns the object from localStorage comparing the structure with emptyBrandAnalyticAdvancedPref
const getFullBrandAnalyticsLocalStorage = () => {
  const ls = localStorage.getItem('BrandAnalyticsAdvancedPref');
  if (ls) {
    return { ...emptyBrandAnalyticAdvancedPref, ...JSON.parse(ls) };
  }
  return { ...emptyBrandAnalyticAdvancedPref };
};

export const useBrandAnalyticsStore = defineStore('brandAnalytics', {
  state: (): State => ({
    brandAnalyticData: null,
    brandAnalyticSearch: null,
    brandAnalyticSort: null,
    brandAnalyticPage: null,
    showAdvancedFilter: false,
    showAdvancedActiveFilter: false,
    brandAnalyticAdvancedPref: getFullBrandAnalyticsLocalStorage(),
    backupBrandAnalyticAdvancedPref: getFullBrandAnalyticsLocalStorage(),
    noOfResults: null,
    openedFilters: [],
    fetchedFiltersData: false,
    currentTab: BrandAnalyticsTabs.BRAND_LIST,
    scrollPosition: 0,
  }),

  getters: {
    appliedFilters: (state) => () => {
      return Object.entries(state.backupBrandAnalyticAdvancedPref)
        .filter(([key, val]) => key !== 'q' && key !== 'params' && val !== '')
        .reduce((acc, [_key, val]) => {
          acc.push(val);
          return acc;
        }, [])
        .flat()
        .sort();
    },

    newFilters: (state) => () => {
      return Object.entries(state.brandAnalyticAdvancedPref)
        .filter(([key, val]) => key !== 'q' && key !== 'params' && val !== '')
        .reduce((acc, [_key, val]) => {
          acc.push(val);
          return acc;
        }, [])
        .flat()
        .sort();
    },
  },

  actions: {
    setBrandAnalyticSort(brandAnalyticSort: string) {
      this.brandAnalyticSort = brandAnalyticSort;
    },

    setBrandAnalyticPage(brandAnalyticPage: number) {
      this.brandAnalyticPage = brandAnalyticPage;
    },

    setBrandAnalytictSearch(brandAnalyticSearch: string) {
      this.brandAnalyticSearch = brandAnalyticSearch;
    },

    setFetchedDataFilters(value: boolean) {
      this.fetchedFiltersData = value;
    },

    setOpenedFilters(filters: string[]) {
      this.openedFilters = filters;
    },

    setCurrentTab(tabName: string) {
      this.currentTab = tabName;
    },

    resetBrandAnalyticsNavigation() {
      this.currentTab = BrandAnalyticsTabs.BRAND_LIST;
      this.scrollPosition = 0;
    },

    setBrandAnalyticAdvancedPref(
      brandAnalyticAdvancedPref: any,
      updateBackup = false
    ) {
      localStorage.setItem(
        'BrandAnalyticsAdvancedPref',
        JSON.stringify(brandAnalyticAdvancedPref)
      );
      this.brandAnalyticAdvancedPref = brandAnalyticAdvancedPref;

      if (updateBackup) {
        this.backupBrandAnalyticAdvancedPref = brandAnalyticAdvancedPref;
      }
    },

    restoreFilters() {
      this.brandAnalyticAdvancedPref = JSON.parse(
        JSON.stringify(this.backupBrandAnalyticAdvancedPref)
      );
    },

    async getBrandAnalyticsAdvanced(reqObj: BrandAnalyticsAdvancedSearchReq) {
      this.noOfResults = null;
      try {
        const promiseBrands = fetchBrandAnalyticsAdvancedBrand(reqObj);
        const promiseConsumerType = fetchBrandAnalyticsAdvancedMap(reqObj);
        const promiseLifestyle = fetchBrandAnalyticsLifestyle(reqObj);

        const [brandResponse, consumerTypeResponse, lifestyleResponse] =
          await Promise.all([
            promiseBrands,
            promiseConsumerType,
            promiseLifestyle,
          ]);

        const { brands } = brandResponse.data;
        this.brandAnalyticData = {
          ...brandResponse.data,
          brands: brands.map((brand) => {
            return {
              ...brand,
              id: brand.businessId,
            };
          }),
        };
        this.noOfResults = brandResponse.data.noOfResults;

        this.brandAnalyticData.brandPositioningMapData =
          consumerTypeResponse.mapList;

        this.brandAnalyticData.lifestyleMapData = lifestyleResponse.mapList;
      } catch (_error) {
        // console.log('no brand response');
      }
    },

    async searchBrandAnalytic(reqObj: BrandAnalyticReq) {
      const reqParams: ParamsOptions = {};
      reqObj.params.map((arr) => {
        reqParams[arr.name] = arr.value;
      });
      const response = await fetchBrandAnalyticSearch(reqParams);
      return response;
    },

    async searchBrandAnalyticById(reqObj: any) {
      const id = reqObj.id;
      const reqParams: ParamsOptions = {};
      reqObj.params.map((arr: any) => {
        reqParams[arr.name] = arr.value;
      });
      const response = await fetchBrandDetail(id, reqParams);
      return response;
    },
  },
});
