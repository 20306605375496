/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
import api from '@/plugins/axiosApi';
import { BrandAnalyticsAdvancedSearchReq } from '@/api/types';
import AdapterCommon from '@/adapters/common';
import { AnnualLaunches } from '@/typings/brands';

export const fetchBrandAnalyticSearch = async (params: any) => {
  const resObj: any = await api({
    url: '/api/brands/v1',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchBrandAnalyticsAdvancedBrand = async (
  params: BrandAnalyticsAdvancedSearchReq
) => {
  const options: any = params.params;
  const jsonData = {
    _sort: options.filter(function (item: any) {
      return item.name === '_sort';
    })[0].value,
    lang: options.filter(function (item: any) {
      return item.name === 'lang';
    })[0].value,
    _limit: options.filter(function (item: any) {
      return item.name === '_limit';
    })[0].value,
    _page: options.filter(function (item: any) {
      return item.name === '_page';
    })[0].value,

    categoryId: params.categoryId,
    subCategoryId: params.subCategoryId,
    marketId: params.marketId,
    brandPosId: params.brandPosId,
    pricePosId: params.pricePosId,
    lifestyleId: params.lifestyleId,
    ...(params.companyId && {
      companyId: params.companyId,
    }),
    q: params.q ? params.q : '*',
  };

  if (params.year && params.year > 0) {
    Object.assign(jsonData, { year: params.year });
  }

  const resObj: any = await api.post(`/api/brands/v1`, jsonData);
  if (resObj) {
    return resObj;
  }
};

export const fetchBrandAnalyticsAdvancedMap = async (
  params: BrandAnalyticsAdvancedSearchReq
) => {
  const options: any = params.params;
  const jsonData = {
    lang: options.filter(function (item: any) {
      return item.name === 'lang';
    })[0].value,
    _limit: options.filter(function (item: any) {
      return item.name === '_limit';
    })[0].value,
    _page: options.filter(function (item: any) {
      return item.name === '_page';
    })[0].value,
    _sort: options.filter(function (item: any) {
      return item.name === '_sort';
    })[0].value,

    categoryId: params.categoryId,
    subCategoryId: params.subCategoryId,
    marketId: params.marketId,
    brandPosId: params.brandPosId,
    pricePosId: params.pricePosId,
    ...(params.companyId && {
      companyId: params.companyId,
    }),
    q: params.q,
  };

  if (params.year && params.year > 0) {
    Object.assign(jsonData, { year: params.year });
  }

  const { data } = await api.post(
    `/api/brands/brand-positioning-map/v1`,
    jsonData
  );
  return data;
};

export const fetchBrandAnalyticsLifestyle = async (
  params: BrandAnalyticsAdvancedSearchReq
) => {
  const options: any = params.params;
  const jsonData = {
    lang: options.filter(function (item: any) {
      return item.name === 'lang';
    })[0].value,
    _limit: options.filter(function (item: any) {
      return item.name === '_limit';
    })[0].value,
    _page: options.filter(function (item: any) {
      return item.name === '_page';
    })[0].value,
    _sort: options.filter(function (item: any) {
      return item.name === '_sort';
    })[0].value,

    categoryId: params.categoryId,
    subCategoryId: params.subCategoryId,
    marketId: params.marketId,
    pricePosId: params.pricePosId,
    lifestyleId: params.lifestyleId,
    ...(params.companyId && {
      companyId: params.companyId,
    }),
    q: params.q,
  };

  if (params.year && params.year > 0) {
    Object.assign(jsonData, { year: params.year });
  }

  const { data } = await api.post(
    `/api/brands/brand-lifestyle-map/v1`,
    jsonData
  );
  return data;
};

export const fetchAllBrands = async (params: any) => {
  const resObj: any = await api({
    url: '/api/brand-selection/v1',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchAllMainBrands = async (params: any) => {
  const resObj: any = await api({
    url: '/api/main-brand-selection',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchBrandDetail = async (brandId: string, params: any) => {
  const resObj: any = await api({
    url: `/api/brands/${brandId}/v1`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchBrandFeedbackAnalysis = async (
  brandId: string,
  params: any
) => {
  const resObj: any = await api({
    url: `/api/brands/${brandId}/feedback-analysis`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchBrandCountryConversationvolume = async (
  brandId: string,
  params: any
) => {
  const resObj: any = await api({
    url: `/api/brands/${brandId}/country-conversationvolume`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchBrandConversationVolumeAndReach = async (
  brandId: string,
  params: any
) => {
  const resObj: any = await api({
    url: `/api/brands/${brandId}/consumer-insights-time`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchBrandConversationVolumeAndReachChina = async (
  brandId: string,
  params: any
) => {
  const resObj: any = await api({
    url: `/api/brands/${brandId}/conversationvolume-reach-time/v1`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchBrandConsumerInsightChina = async (
  brandId: string,
  params: any
) => {
  const resObj: any = await api({
    url: `/api/brands/${brandId}/consumer-insight/v1`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchTopTrendingTopics = async (brandId: string, params: any) => {
  const resObj: any = await api({
    url: `/api/brands/${brandId}/top-trending-topics`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchTop3BrandNewLaunched = async (
  brandId: string,
  params: any
) => {
  const resObj: any = await api({
    url: `/api/brands/${brandId}/top-launched-products/v2`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchBrandRetailValueAndMarketShare = async (
  businessId: string,
  params: any
) => {
  const resObj: any = await api({
    url: `/api/brands/${businessId}/marketshare/v1`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchBrandRetailValueAndGrowthRate = async (
  businessId: string,
  params: any
) => {
  const resObj: any = await api({
    url: `/api/brands/${businessId}/growthrate/v1`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
  return [];
};

export const fetchBrandSourceConversationVolume = async (
  brandId: string,
  params: any
) => {
  const resObj: any = await api({
    url: `/api/brands/${brandId}/source-conversationvolume`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchBrandChinaProvinces = async (
  brandId: string,
  params?: any
) => {
  const resObj: any = await api({
    url: `/api/brands/${brandId}/province/v1`,
    method: 'get',
    params,
  });
  if (resObj) {
    const { datasets } = AdapterCommon.chinaProvincesClicks(resObj.data);
    return datasets;
  }
};

export const fetchAnnualLaunches = async (
  brandId: string,
  params?: any
): Promise<AnnualLaunches> => {
  const { data } = await api({
    url: `/api/products/annual-launched/year-distribution/v2/${brandId}`,
    method: 'get',
    params,
  });

  return data;
};

export const fetchAnnualLaunchesByCategory = async (
  brandId: string,
  params?: any
): Promise<AnnualLaunches> => {
  const { data } = await api({
    url: `/api/products/annual-launched/category-distribution/v2/${brandId}`,
    method: 'get',
    params,
  });

  return data;
};
