<template>
  <div
    :class="[
      'dlite-main-drawer',
      {
        'dlite-main-drawer__collapsed': isLowerThanMD && !isDrawerExpanded,
      },
    ]"
    :style="mainDrawerStyle"
    :expanded="isDrawerExpanded"
  >
    <header
      v-if="isDrawerFullScreen"
      class="dlite-main-drawer__fullscreen-header"
    >
      <button
        :class="[
          'dlite-main-drawer__fullscreen-header__close-btn',
          {
            'dlite-main-drawer__fullscreen-header__close-btn--hidden':
              !isDrawerExpanded,
          },
        ]"
        :aria-label="$tc('app.accessibility.close-navigation')"
        @click="hideDrawer"
      >
        <BaseIcon :icon="ICONS.CLOSE" />
      </button>
    </header>
    <nav ref="mainDrawer" :id="mainDrawerId" :aria-expanded="isDrawerExpanded">
      <ul>
        <li
          v-for="eco in apps"
          :key="eco.id"
          :class="[
            listItemClass(eco),
            'drawer-item',
            getItemClass(eco),
            { 'drawer-item--selected': isSelected(eco) },
          ]"
        >
          <a
            v-if="eco.newPage"
            target="_blank"
            class="menu-router-link"
            :href="eco.href"
            @click="trackRedirection(eco)"
          >
            <v-tooltip
              content-class="menu-tooltip"
              right
              nudge-right="6"
              transition="slide-x-transition"
              :disabled="!drawerMini || isMobile"
            >
              <template #[`activator`]="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :class="'float-left drawer-icon ' + eco.icon"
                ></v-icon>
              </template>
              <span>{{ $t(eco.text) }}</span>
            </v-tooltip>
            <span class="normalTextColor ml-5 pt-1">
              {{ $t(eco.text) }}
            </span>
          </a>

          <!-- TODO: Vue 3 deprecation: Using click.native in router-link is deprecated in Vue3 -->
          <router-link
            class="menu-router-link"
            :class="!eco.isAllowed ? 'disabled' : ''"
            :to="eco.id !== route.name ? eco.href : {}"
            @click.native="closeDrawer"
          >
            <v-tooltip
              content-class="menu-tooltip"
              right
              nudge-right="6"
              transition="slide-x-transition"
              :disabled="!drawerMini || isMobile"
            >
              <template #[`activator`]="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :class="[
                    'float-left',
                    'drawer-icon',
                    { 'drawer-icon--selected': isSelected(eco) },
                    eco.icon,
                  ]"
                ></v-icon>
              </template>
              <span
                :style="{
                  color: isSelected(eco) ? eco.color : '',
                }"
              >
                {{ $t(eco.text) }}
              </span>
            </v-tooltip>
            <span
              :style="{
                color: isSelected(eco) ? 'white' : '',
              }"
              class="title-item ml-5"
              >{{ $t(eco.text) }}
            </span>
          </router-link>
        </li>
      </ul>
      <div v-if="!isLowerThanMD" aria-hidden="true" class="mt-4 drawer-chevron">
        <v-btn icon @click.stop="toggleMiniDrawer" class="drawer-chevron-btn">
          <v-icon v-if="drawerMini" large class="drawer-chevron"
            >mdi-chevron-right</v-icon
          >
          <v-icon v-else large class="drawer-chevron">mdi-chevron-left</v-icon>
        </v-btn>
      </div>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { EcoSystem } from '@/api/types';
import { useMainDrawer } from '@/composables/useMainDrawer';
import { useRouter } from '@/composables/useRouter';
import { useHeaderAndFooterStore } from '@/stores/headerAndFooter';
import { useI18n } from '@/composables/useI18n';
import { trackEvent } from '@/lib/analytics';
import { useResponsive } from '@/composables/useResponsive';
import { onClickOutside } from '@vueuse/core';
import { ICONS } from '@/typings/enums';
import BaseIcon from '@/components/common/base/BaseIcon.vue';

// TODO: Fix scrollbar extra space - center icons when scroll appears (not for now)

interface Props {
  apps: EcoSystem[];
}

defineProps<Props>();

const { route, router } = useRouter();
const { $t, $tc } = useI18n();
const headerAndFooter = useHeaderAndFooterStore();
const { drawerButtonTrigger, mainDrawerId, isDrawerExpanded, hideDrawer } =
  useMainDrawer();
const { isLowerThanMD, isDrawerFullScreen, screenWidth, isMobile } =
  useResponsive();

const drawerMini = ref(true);
const mainDrawer = ref<HTMLElement | null>(null);

onClickOutside(
  mainDrawer,
  () => {
    if (isDrawerExpanded.value) {
      hideDrawer();
    }
    if (!isLowerThanMD.value && !drawerMini.value) {
      drawerMini.value = true;
    }
  },
  { ignore: [drawerButtonTrigger] }
);

const mainDrawerStyle = computed(() => {
  let top = `${headerAndFooter.drawerPosition}px`;
  let height = `calc(100lvh - ${headerAndFooter.drawerPosition}px`;
  let width: string;
  let inlinePadding =
    isDrawerExpanded.value || !isLowerThanMD.value ? '0.45rem' : '0';
  if (isDrawerFullScreen.value) {
    width = '100%';
    top = '0';
    height = '100lvh';
  } else if (!drawerMini.value || isLowerThanMD.value) {
    width = `${headerAndFooter.drawerWidthMaxi}px`;
  } else {
    width = `${headerAndFooter.drawerWidthMini}px`;
  }
  return `top: ${top}; width: ${width}; height: ${height}; padding-inline: ${inlinePadding}`;
});

const toggleMiniDrawer = () => {
  drawerMini.value = !drawerMini.value;
};

const listItemClass = (eco: EcoSystem) => {
  let itemClass = `type-${eco.icon}`;
  const matched = router.currentRoute.matched.find((route) => {
    if (eco.relatedSection) {
      return route.path.startsWith(eco.relatedSection);
    }
    return route.path === eco.href;
  });

  if (matched) {
    return itemClass.concat(' active');
  }

  return itemClass;
};

const getItemClass = (eco: EcoSystem) => {
  return eco.icon?.split(' ')[0].replace('icon-', 'drawer-item-');
};

const isSelected = (eco: EcoSystem) => {
  if (eco.href === '/ci' && route.value.path.startsWith('/ci-mo')) return false;
  return (
    (eco.relatedSection && route.value.path.startsWith(eco.relatedSection)) ||
    route.value.path.startsWith(eco.href)
  );
};

const closeDrawer = () => {
  drawerMini.value = !isLowerThanMD.value;
  if (isLowerThanMD.value && isDrawerExpanded.value) {
    hideDrawer();
  }
};

const trackRedirection = (item: EcoSystem) => {
  trackEvent({
    event_type: 'navigation',
    event_category: 'click',
    event_action: 'link',
    event_value: item.href,
    page_type: $t(item.text, null, 'en'),
  });
  closeDrawer();
};

if (!isLowerThanMD.value) {
  isDrawerExpanded.value = true;
}

// Make sure drawer is closed on screenWidth changes (mini & expanded)
watch(
  screenWidth,
  () => {
    if (!isLowerThanMD.value && !drawerMini.value) {
      drawerMini.value = true;
    }
    if (
      isDrawerExpanded.value &&
      (!isLowerThanMD.value ||
        (!isDrawerFullScreen.value && isLowerThanMD.value))
    ) {
      hideDrawer();
    }
  },
  { immediate: true }
);
</script>

<style scoped lang="scss">
.dlite-main-drawer {
  position: fixed;
  overflow: hidden auto;
  z-index: 100;
  padding-top: 0.6rem;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.1);
  background-color: white;
  scroll-margin-right: -1rem;
  scroll-margin-left: 1rem;

  transition: width 0.2s ease-out, padding-inline 0.2s ease-out,
    top 0.02s ease-out;

  &__collapsed {
    width: 0 !important;
    overflow: hidden;
  }

  &__fullscreen-header {
    text-align: right;
    margin-top: 2.5rem;
    &__close-btn {
      visibility: visible;
      position: fixed;
      top: 1rem;
      right: 1rem;
      padding: 0.7rem;
      width: 2.5rem;
      height: 2.5rem;
      opacity: 1;
      transition: opacity 0.2s ease-out 0.2s;
    }
    &__close-btn--hidden {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0s 0s;
    }
  }

  ul {
    display: grid;
    gap: 0.5rem;
    list-style: none;
    padding: 0;
  }
}

.title-item {
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.87);
}

.drawer-item {
  overflow: hidden;
  display: flex;
  justify-content: left;

  transition: color 0.15s ease-out, background-color 0.15s ease-out;

  a {
    display: flex;
    gap: 0.15rem;
    align-items: center;
    padding: 0.7rem 0.9rem;

    i {
      margin-top: 1px;
    }
  }

  span {
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 1rem;
  }
}

.menu-tooltip {
  font-size: 0.8125rem;
  font-weight: 500;
  opacity: 1 !important;
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  padding: 17px 15px;
  min-width: 180px;
  border-right: 1px rgba(0, 0, 0, 0.12) solid;
  transform-origin: left;
  margin-left: -1px;
  margin-top: -2px;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);

  &.v-tooltip__content {
    z-index: 90 !important;
  }
}

.drawer-chevron-btn {
  color: var(--base) !important;
  border: 1px solid var(--base);
  width: 100%;
  height: 40px;
}
</style>
