/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useI18n } from '@/composables/useI18n';
import { useRouter } from '@/composables/useRouter';
import { defineStore } from 'pinia';

interface State {
  width: number;
  drawerPosition: number;
  drawerWidthMini: number;
  drawerWidthMaxi: number;
  drawerGroupedWidthMini: number;
  drawerGroupedWidthMaxi: number;
  headerHeight: number;
  fixedHeaderHeight: number;
  loginHeaderHeight: number;
  homeHeaderHeight: number;
  footerHeight: number;
  moduleTitle: string;
  moduleDescription: string;
  selectedSubmodule: string;
  selectedModule: string;
}

const { $t } = useI18n();

const fixedFooterMargin = 26;

export const useHeaderAndFooterStore = defineStore('headerAndFooter', {
  state: (): State => ({
    width: 1920,
    drawerPosition: 96,
    drawerWidthMini: 64,
    drawerWidthMaxi: 273,
    drawerGroupedWidthMini: 56,
    drawerGroupedWidthMaxi: 252,
    headerHeight: 70,
    fixedHeaderHeight: 70,
    loginHeaderHeight: 70,
    homeHeaderHeight: 96,
    footerHeight: 50 + fixedFooterMargin,
    moduleTitle: $t('app.common.value.name'),
    moduleDescription: $t('app.header.descriptions.dlite'),
    selectedSubmodule: '',
    selectedModule: '',
  }),

  getters: {
    subheader(state) {
      const { route } = useRouter();
      state.moduleTitle = $t(route.value.meta.title || 'app.common.value.name');
      state.moduleDescription = $t(
        route.value.meta.description || 'app.header.descriptions.dlite'
      );
      return `
        <div class="subheader-content">
          <span class="subheader-content-section" style="color: var(--base)">${state.moduleTitle}</span>
          <span class="subheader-content-description">${state.moduleDescription}</span>
        </div>
      `;
    },
  },

  actions: {
    setWidth(value: number) {
      this.width = value;
    },
    setDrawerPosition(value: number) {
      this.drawerPosition = value;
    },
    setHeaderHeight(value: number) {
      this.headerHeight = value;
    },
    setFixedHeaderHeight(value: number) {
      if (this.fixedHeaderHeight !== value) {
        this.fixedHeaderHeight = value;
      }
    },
    setFooterHeight(value: number) {
      this.footerHeight = value + fixedFooterMargin;
    },
    setModuleTitle(payload: string) {
      this.moduleTitle = payload;
    },
    setSubheader(payload: string) {
      this.subheader = payload;
    },
    setSelectedSubmodule(payload: string) {
      this.selectedSubmodule = payload;
    },
    setSelectedModule(payload: string) {
      this.selectedModule = payload;
    },
  },
});
