<template>
  <div
    v-if="routeName && routeName.startsWith(SectionsIds.EMOLLIENT_JOCKEY)"
    class="d-flex mb-4 pr-5 white"
  >
    <v-tabs height="48" slider-size="3" class="pr-3 tabs-mobile">
      <v-tab
        class="caption font-weight-medium"
        :to="{ name: SectionsIds.EMOLLIENT_JOCKEY_LIST }"
      >
        {{ $t('app.page.emollient-jockey.list') }}
      </v-tab>
      <v-tab
        class="caption font-weight-medium"
        :to="{ name: SectionsIds.EMOLLIENT_JOCKEY_RADAR }"
      >
        {{ $t('app.page.emollient-jockey.radar-chart') }}
      </v-tab>
      <v-tab
        class="caption font-weight-medium"
        :to="{ name: SectionsIds.EMOLLIENT_JOCKEY_MATRIX }"
      >
        {{ $t('app.page.emollient-jockey.matrix') }}
      </v-tab>
    </v-tabs>
    <div class="d-flex align-center white">
      <ChartInformationDots
        :folder-name="$options.name"
        :has-snapshot="false"
        :key="`${routeName}-jockey`"
      />
    </div>
  </div>
  <div
    v-else-if="routeName && routeName.startsWith(SectionsIds.EMOLLIENT_MAESTRO)"
    class="d-flex pr-5 white"
  >
    <v-tabs height="48" slider-size="3" class="pr-3 tabs-mobile">
      <v-tab
        class="caption font-weight-medium"
        :to="{ name: SectionsIds.EMOLLIENT_MAESTRO_EASY_MODE }"
      >
        {{ $t('app.page.emollient-maestro.easy-mode') }}
      </v-tab>
      <v-tab
        class="caption font-weight-medium"
        :to="{ name: SectionsIds.EMOLLIENT_MAESTRO_ADVANCED_MODE }"
      >
        {{ $t('app.page.emollient-maestro.advanced-mode') }}
      </v-tab>
    </v-tabs>
    <div class="d-flex align-center ml-auto white">
      <ChartInformationDots
        :folder-name="routeName"
        :has-snapshot="false"
        :key="`${routeName}-maestro`"
      />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'DliteHeaderNav',
};
</script>

<script setup lang="ts">
import ChartInformationDots from '@/components/common/ChartInformationDots.vue';
import { SectionsIds } from '@/typings/enums';

interface Props {
  routeName?: string;
}

withDefaults(defineProps<Props>(), {
  routeName: '',
});
</script>
