export enum HomeItemsIds {
  FREE = 'free',
  HOWTO = 'howTo',
  MORE = 'moreApps',
}

export enum SliderValues {
  DEACTIVATED = 0,
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
  VERYHIGH = 4,
}

export enum SectionGroupIds {
  MY_FAVORITES = 'myfavorites',
  PRODUCT_DEVELOPMENT = 'productDevelopment',
  MARKET_INTELLIGENCE = 'marketIntelligence',
  LEARNING_AREA = 'learningArea',
  PERSONAL_AREA = 'personalArea',
  DISCOVER_MORE = 'discoverMore',
}

export enum SectionsIds {
  LANDING = 'landing',
  LOGIN = 'login',
  HOME = 'home',
  SFA = 'sfa',
  BASF_STORE = 'basfStore',
  QUICK_SEARCH = 'quickSearch',
  CARE_STORE = 'careStore',
  CARE_360 = 'care360',
  BRENNTAG = 'brenntag',
  REQUEST_BUNDLE = 'request-bundle',
  BRAND_ANALYTICS = 'brandAnalytics',
  BRAND_DETAILS = 'brandDetails',
  INGREDIENT_SELECTION = 'ingredientSelection',
  INGREDIENT_DETAILS = 'ingredientDetails',
  COMPOSITIONS = 'cleanBeauty',
  CONSUMER_PRODUCTS = 'consumerProducts',
  PRODUCT_DETAILS = 'productDetails',
  CONSUMER_INSIGHTS = 'consumerInsights',
  MARKET_INSIGHTS = 'marketInsights',
  CONCEPT_COLLECTION = 'conceptCollection',
  TREND = 'trend',
  TREND_DETAILS = 'trendDetails',
  CONCEPT_COLLECTION_BRIEF_ANALYSIS = 'conceptCollectionBriefAnalysis',
  CONCEPT_DETAILS = 'conceptDetails',
  BASF_PRODUCTS = 'basfProducts',
  BASF_PRODUCT_DETAILS = 'basfProductDetails',
  FORMULATION_DESIGN = 'formulationDesign',
  FORMULATION_SOLUTIONS = 'formulationSolutions',
  FORMULATION_DETAILS = 'formulationDetails',
  MY_DLITE = 'myDlite',
  MY_SEARCH = 'mySearch',
  MY_COMPARISON = 'myComparison',
  MY_PROJECT = 'myProjects',
  AUTH_CALLBACK = 'authCallback',
  PUBLIC_BASE = 'publicBase',
  CONTACT_OVERVIEW = 'contactOverview',
  TERMS_OVERVIEW = 'termsOverview',
  PRIVACY_OVERVIEW = 'privacyOverview',
  IMPRINT_OVERVIEW = 'imprintOverview',
  MARKETING_OVERVIEW = 'marketingOverview',
  USER_REGISTRATION = 'userRegistration',
  USER_REGISTRATION_SUCCESS_PAGE = 'userRegistrationSuccessfullyPage',
  ERROR_PAGE = 'errorPage',
  LEARN_MORE = 'videoModule',
  DLITE_ACADEMY = 'dliteAcademy',
  CARE_CONNECT = 'careConnect',
  SURFACTANT_NAVIGATOR = 'surfactantNavigator',
  EMOLLIENT_JOCKEY = 'emollientJockey',
  EMOLLIENT_JOCKEY_LIST = 'emollientJockeyList',
  EMOLLIENT_JOCKEY_RADAR = 'emollientJockeyRadar',
  EMOLLIENT_JOCKEY_MATRIX = 'emollientJockeyMatrix',
  EMOLLIENT_MAESTRO = 'emollientMaestro',
  EMOLLIENT_MAESTRO_EASY_MODE = 'emollientMaestroEasyMode',
  EMOLLIENT_MAESTRO_ADVANCED_MODE = 'emollientMaestroAdvancedMode',
  EMOLLIENT_MAESTRO_ADVANCED_MODE_CANDIDATE = 'emollientMaestroAdvancedModeCandidate',
  DEFAULT = 'default',
}

export const enum FormulationAccessibles {
  FORMULATION_DESIGN_DETAIL = 'formulationDesignDetail',
  FORMULATION_DESIGN_PDF = 'formulationDesignFormulationPDF',
  FORMULATION_DESIGN_COMPARE = 'formulationDesignCompare',
  FORMULATION_DESIGN_RELATED_BASF_PRODUCTS = 'basfProducts',
  FORMULATION_DESIGN_RELATED_CONCEPTS = 'conceptCollection',
  FORMULATION_DESIGN_RELATED_CONSUMER_PRODUCTS = 'consumerProducts',
  FORMULATION_DESIGN_INGREDIENTS = 'formulationDesignIngredients',
  FORMULATION_DESIGN_ADDITIONAL_INFO = 'formulationDesignAdditionalInfo',
}

export const enum BasfProductAccessibles {
  BASF_PRODUCTS_ADDITIONAL_FILTERS = 'basfProductsAdditionalFilters',
  BASF_PRODUCTS_SUSTAINABILITY_INFORMATION = 'basfProductsAdditionalData',
  BASF_PRODUCTS_DOC_EXPORT = 'basfProductsDocExport',
  BASF_PRODUCTS_RELATED_FORMULATION_DISPLAY = 'formulationDesign',
  BASF_PRODUCTS_RELATED_FORMULATION_DETAIL = 'formulationDesignDetail',
  BASF_PRODUCTS_RELATED_CONSUMER_PRODUCTS = 'consumerProducts',
  BASF_PRODUCTS_CARE_STORE = 'careStore',
}

export const enum FormulationAccessType {
  FREEMIUM = 'FREEMIUM',
  FREEMIUM_PLUS = 'FREEMIUM_PLUS',
}

export enum SearchItemId {
  BRAND_ANALYTICS,
  CONSUMER_PRODUCTS,
  FORMULATION_DESIGN,
  INGREDIENTS_SELECTION,
  BASF_PRODUCTS,
  LEARN_MORE,
  CONCEPT_COLLECTION,
}

export enum VideoStatus {
  PAYING,
  PAUSED,
}

export enum BASFProductContent {
  SEGMENTS,
  PCF,
  GROUPS,
  APPLICATION,
  LABELS,
  FUNCTION,
  CONSUMER_BENEFIT,
}

// DEV / QA values
export enum BASFProductPCF {
  RSPO = '8a80816e7c9ecd69017cc2304499285d',
  BIOGENIC = '8a80816e7c9ecd69017cc230a048285f',
  SCOTT = '8a8082027d2403ec017d386932202456',
}

export const enum LearnMoreFilters {
  CATEGORY = 'CATEGORY',
  VIDEO_LENGTH = 'VIDEO_LENGTH',
  LANGUAGE = 'LANGUAGE',
}

export enum BrandFilters {
  CONSUMER_TYPE = 'CONSUMER_TYPE',
  LIFE_STYLE = 'LIFE_STYLE',
  PRICE_POSITIONING = 'PRICE_POSITIONING',
  CATEGORY = 'CATEGORY',
  SUB_CATEGORY = 'SUB_CATEGORY',
  COUNTRY = 'COUNTRY',
  DATE = 'DATE',
  COMPANY = 'COMPANY',
}

export enum ProductsFilters {
  CATEGORY = 'CATEGORY',
  SUB_CATEGORY = 'SUB_CATEGORY',
  FORMATS = 'FORMATS',
  CLAIMS = 'CLAIMS',
  COUNTRY = 'COUNTRY',
  RETAIL_PRICE = 'RETAIL_PRICE',
  DATE = 'DATE',
  SPF = 'SPF',
  PA = 'PA',
  COMPANY = 'COMPANY',
  MAIN_BRANDS = 'MAIN_BRANDS',
  BRANDS = 'BRANDS',
  FUNCTION = 'FUNCTION',
  INGREDIENTS_INCLUDE = 'INGREDIENTS_INCLUDE',
  INGREDIENTS_EXCLUDE = 'INGREDIENTS_EXCLUDE',
}

export enum FormulationsFilters {
  CATEGORY = 'CATEGORY',
  SUB_CATEGORY = 'SUB_CATEGORY',
  CONSUMER_BENEFIT = 'CONSUMER_BENEFIT',
  LABELS = 'LABELS',
  FORMATS = 'FORMATS',
  SPF = 'SPF',
  TRADENAMES_INCLUDE = 'TRADENAMES_INCLUDE',
  TRADENAMES_EXCLUDE = 'TRADENAMES_EXCLUDE',
  INGREDIENTS_INCLUDE = 'INGREDIENTS_INCLUDE',
  INGREDIENTS_EXCLUDE = 'INGREDIENTS_EXCLUDE',
  RELEASED_FOR = 'RELEASED_FOR',
  RENEWABLE_CARBON_CONTENT = 'RENEWABLE_CARBON_CONTENT',
  BIODEGRADABILITY = 'BIODEGRADABILITY',
}

export enum BasfProductsFilters {
  CATEGORY = 'CATEGORY',
  APPLICATION = 'APPLICATION',
  GROUPS = 'GROUPS',
  ACTIVE_SUBSTANCES = 'ACTIVE_SUBSTANCES',
  FUNCTION = 'FUNCTION',
  ETHICS_LABELS = 'ETHICS_LABELS',
  RETAIL_LABELS = 'RETAIL_LABELS',
  CONSUMER_BENEFIT = 'CONSUMER_BENEFIT',
  INGREDIENTS_INCLUDE = 'INGREDIENTS_INCLUDE',
  INGREDIENTS_EXCLUDE = 'INGREDIENTS_EXCLUDE',
  RENEWABLE_CARBON_CONTENT = 'RENEWABLE_CARBON_CONTENT',
  ECOLABELS_SUITABILITY = 'ECOLABELS_SUITABILITY',
  SUSTAINABILITY_NATURALITY_LABELS = 'SUSTAINABILITY_NATURALITY_LABELS',
  SUSTAINABLE_SOURCING_STANDARDS_LABELS = 'SUSTAINABLE_SOURCING_STANDARDS_LABELS',
  SUSTAINABILITY_BIODEGRADABILITY_LABELS = 'SUSTAINABILITY_BIODEGRADABILITY_LABELS',
}

export enum IngredientsFilters {
  CATEGORY = 'CATEGORY',
  SUB_CATEGORY = 'SUB_CATEGORY',
  FUNCTION = 'FUNCTION',
  RELEASED_LOCATION = 'RELEASED_LOCATION',
}

export enum TopClaimsFilters {
  DATE = 'DATE',
  CATEGORY = 'CATEGORY',
  SKIN_TYPE = 'SKIN_TYPE',
  HAIR_TYPE = 'HAIR_TYPE',
  COUNTRY = 'COUNTRY',
  GENDER = 'GENDER',
  SENTIMENT = 'SENTIMENT',
}

export enum CompositionsFormulationFilters {
  CATEGORY = 'CATEGORY',
  SUB_CATEGORY = 'SUB_CATEGORY',
  FORMATS = 'FORMATS',
}

export enum ConceptsFilters {
  CATEGORY = 'CATEGORY',
  REGION = 'REGION',
  YEAR = 'YEAR',
  LANGUAGE = 'LANGUAGE',
}

export enum TrendsFilters {
  CATEGORY = 'CATEGORY',
  REGION = 'REGION',
  YEAR = 'YEAR',
  LANGUAGE = 'LANGUAGE',
  LIFE_STYLE = 'LIFE_STYLE',
}

export enum CompositionFormulationProperties {
  CATEGORY = 'CATEGORY',
  SUB_CATEGORY = 'SUB_CATEGORY',
  FORMATS = 'FORMATS',
  CONSUMER_BENEFIT = 'CONSUMER_BENEFIT',
}
export enum CompositionsIngredientsFilters {
  FUNCTION = 'FUNCTION',
  GROUPS = 'GROUPS',
}

export enum TopTopics {
  ALL_NATURAL_PRODUCT = 'LlNtrlP:DscssdT:2657D893A223A0105E3E04EDEA984FD4',
}

export enum ConsumerInsightDates {
  LAST_MONTH = 'LAST_MONTH',
  LAST_TWO_MONTHS = 'LAST_TWO_MONTHS',
  LAST_THREE_YEARS = 'LAST_THREE_YEARS',
}

export enum TopBrandsFilters {
  TOP_TOPICS = 'TOP_TOPICS',
  CATEGORY = 'CATEGORY',
  SUB_CATEGORY = 'SUB_CATEGORY',
  COUNTRY = 'COUNTRY',
  DATE = 'DATE',
}

export enum SurfactantFilters {
  NATURALNESS = 'naturalness',
  INGREDIENT_CONSTRAINTS = 'sustainability',
  RATE_FOAMING = 'rateOfFoaming',
  FOAM_HEIGHT_STABILITY = 'foamHeightStability',
  FOAM_HEIGHT_30 = 'flashFoam30',
  FOAM_HEIGHT_100 = 'flashFoam100',
  FOAM_HEIGHT_160 = 'foamHeight160',
  FOAM_HEIGHT_220 = 'foamHeight220',
  FOAM_HEIGHT_280 = 'foamHeight280',
  SFT_RATE_DECREASE = 'sftRateOfDecrease',
  SFT_EQUILIBRIUM = 'sftEquilibrium',
  IFT_RATE_DECREASE = 'iftRateOfDecrease',
  IFT_EQUILIBRIUM = 'iftEquilibrium',
  ELASTIC_MODULUS = 'elasticModulus',
  MEAN_BUBBLE_SIZE = 'meanBubbleSize',
  DECAY_BUBBLE_SIZE = 'decayOfBubbleSize',
}

export enum PCFStatus {
  NO_DATA = 'NO_DATA',
  NO_ACCESS = 'NO_ACCESS',
  VIEW_ONLY = 'VIEW_ONLY',
  FULL_ACCESS = 'FULL_ACCESS',
}

export enum CareConnectSections {
  BEAUTYLITIC = 'Beautylitic',
  BEAUTY40 = 'Beauty 4.0',
  PERSONALIZED_COSMETICS = 'Personalized Cosmetics Machine',
  PRINTING_FACE = 'Printing face mask',
  ENVIRONMENTAL_STRESS = 'Environmental Stress Advisors',
  SMART_HAIRBRUSH = 'Smart Hairbrush',
  SELFIE_SKIN = 'Selfie Skin',
}

export enum VideoTags {
  HOME = '__dlite_landing__',
  CARE_CONNECT = '__dlite_careConnect__',
}

export enum WebshopLink {
  CARESTORE = 'carestore',
  BRENNTAG = 'brenntag',
  BASF = 'shop.basf.com',
}

// cross-env values
export enum ENV {
  LOCAL = 'development',
  DEV = 'dev',
  QA = 'qual',
  PROD = 'prod',
}

export enum ProductType {
  BEVOL = 'BEVOL',
  MINTEL = 'MINTEL',
}

export enum Order {
  ASC,
  DESC,
}

export enum RelatedTabs {
  BasfProducts = 'relatedBasfProductsTab',
  Formulations = 'relatedFormuationsTab',
  ConsumerProducts = 'relatedConsumerProductsTab',
}

export enum FieldType {
  PHYSICAL = 'PHYSICAL',
  SENSORIC = 'SENSORIC',
}

export enum ConceptCollectionColors {
  LIGHTBLUE = 'LIGHTBLUE',
  DARKGREEN = 'DARKGREEN',
  DARKBLUE = 'DARKBLUE',
  RED = 'RED',
  ORANGE = 'ORANGE',
}

export enum PhysicoChemicalProps {
  INTERFACIAL_TENSION = 'interfacialTension',
  REFRACTIVE_INDEX = 'refractiveIndex',
  DENSITY = 'density',
  ACN = 'equivalentAlkaneCarbonNumber',
  SPREADABILITY = 'spreadability',
  VISCOSITY = 'viscosity',
  SURFACE_TENSION = 'surfaceTension',
}

export enum SensorialProps {
  WAXINESS = 'waxiness',
  STICKINESS = 'stickiness',
  GLIDING = 'gliding',
  DISTRIBUTION = 'distribution',
  CARED_FOR_FEEL = 'caredForFeel3min',
  LIGHT_SKIN_FEEL = 'lightSkinFeel3min',
  POWDERY_3MIN = 'powdery3min',
  WAXINESS_3MIN = 'waxiness3min',
  STICKINESS_3MIN = 'stickiness3min',
  ABSORPTOP_3MIN = 'absorption3min',
  ABSORPTION_1MIN = 'absorption1min',
  ACCEPTANCE = 'acceptance',
}

export const enum Currency {
  USD = 'USD Million',
  EUR = 'EUR Million',
  GBP = 'GBP Million',
  CHF = 'CHF Million',
  JPY = 'JPY Million',
}

export const enum RetailType {
  RSP = 'RSP',
  VOLUME = 'VOLUME',
  RSP_GROWTH = 'RSP_GROWTH',
  VOLUME_GROWTH = 'VOLUME_GROWTH',
}

export enum IngredientSortOptions {
  AMOUNT = 'productAmount',
  NAME = 'sortName',
  SCORE = 'search.score()',
  CLICKS = 'clicks',
}

export enum BannerType {
  SUCCESS,
  ERROR,
  WARNING,
  INFO,
}

export const enum FilterType {
  RANGE,
  CHECKBOX,
}

export const enum Login {
  EXTERNAL = 0,
  INTERNAL = 1,
}

export enum ComparisonType {
  BRAND_ANALYTICS,
  CONSUMER_PRODUCTS,
  FORMULATION_DESIGN,
}

export const enum ICONS {
  BRAND_ANALYTICS_ICON = 'brand_analytics_icon',
  CONSUMER_PRODUCTS_ICON = 'consumer_products_icon',
  CONSUMER_INSIGHTS_ICON = 'consumer_insights_icon',
  MARKET_INSIGHTS_ICON = 'market_insights_icon',
  CONCEPT_COLLECTION_ICON = 'concept_collection_icon',
  FORMULATION_DESIGN_ICON = 'formulation_design_icon',
  CUSTOM_FORMULATION_DESIGN_ICON = 'formulation_design_icon',
  INGREDIENT_SELECTION_ICON = 'ingredient_selection_icon',
  MYDLITE_ICON = 'my_dlite_icon',
  EMOLLIENT_JOCKEY_ICON = 'emollient_icon',
  VIDEO_MODULE_ICON = 'video_icon',
  CARE_360_ICON = 'care_360_icon',
  CARE_CONNECT_ICON = 'care_connect_icon',
  SFA_ICON = 'sfa_icon',
  EMOLLIENT_MAESTRO_ICON = 'maestro_icon',
  SURFACTANT_NAVIGATOR = 'surfactant_navigator_icon',
  CLOSE = 'close_icon',
  ARROW_FORWARD = 'arrow_forward_icon',
  MORE_VERT = 'more_vert_icon',
  TREND_ICON = 'trends_icon',
  COMPOSITIONS = 'compositions_icon',
}

export const enum ProductSection {
  LIST,
  TOP_CLAIMS,
  TOP_INGREDIENTS,
}

export enum Usergroup {
  LITE = 'LITE',
  CLUB = 'CLUB',
  INTERNAL = 'INTERNAL',
}

export const enum ComparabilityType {
  CREDO = 'Credo',
  HAZARDOUS_100 = 'Hazardous 100',
  RED = 'RED',
  AMAZON = 'Amazon',
  EU_COSING = 'EU COSING',
  TARGET = 'Target',
  SEPHORA = 'Sephora',
  CHEMICALS_OF_CONCERN = 'Chemicals of Concern',
  RITE = 'Rite',
  HEL = 'HEL',
  DIRTY_DOZEN = 'Dirty Dozen',
}

export const enum SustainabilityType {
  COSMOS = 'COSMOS',
  NATRUE = 'NATRUE',
  BIODEGRADABILITY_LONG = 'BIODEGRADABILITY_LONG',
  BIODEGRADABILITY_SHORT = 'BIODEGRADABILITY_SHORT',
  RSPO = 'RSPO',
  PCF_SCOTT_EXCLUDING_BIOGENIC = 'PCF_SCOTT_EXCLUDING_BIOGENIC',
  PCF_SCOTT_VALIDATION_SCORE = 'PCF_SCOTT_VALIDATION_SCORE',
  PCF_BIOGENIC = 'PCF_BIOGENIC',
  BIOGENIC_CARBON = 'BIOGENIC_CARBON',
  TOTAL_CARBON_CONTENT = 'TOTAL_CARBON_CONTENT',
  RENEWABLE_CARBON_CONTENT = 'RENEWABLE_CARBON_CONTENT',
  EU_ECOLABEL_LEAVE_ON = 'EU_ECOLABEL_LEAVE_ON',
  EU_ECOLABEL_RINSE_OFF = 'EU_ECOLABEL_RINSE_OFF',
  NORDIC_ECOLABEL_LEAVE_ON = 'NORDIC_ECOLABEL_LEAVE_ON',
  NORDIC_ECOLABEL_RINSE_OFF = 'NORDIC_ECOLABEL_RINSE_OFF',
  SUSTAINABLE_SOURCING_STANDARDS = 'SUSTAINABLE_SOURCING_STANDARDS',
}
export const enum SustainabilityTypeValue {
  APPROVED = 'Approved',
  CERTIFIED = 'Certified',
  SUITABLE = 'Suitable',
  SUITABLE_RESTRICTED = 'Suitable Restricted',
  NOT_SUITABLE = 'Not Suitable',
  SUCCESS_CASTOR = 'SuCCESS Certified - Castor',
  RAINFOREST_ALLIANCE = 'Rainforest Alliance Certified - Coconut',
  RSPO_CERTIFIED_PALM = 'RSPO Certified - Palm',
}

export const ComparabilityTypeLabelShort: {
  [k in ComparabilityType]: string;
} = {
  [ComparabilityType.CREDO]: '',
  [ComparabilityType.HAZARDOUS_100]: '100',
  [ComparabilityType.RED]: '',
  [ComparabilityType.AMAZON]: '',
  [ComparabilityType.EU_COSING]: '',
  [ComparabilityType.TARGET]: '',
  [ComparabilityType.SEPHORA]: '',
  [ComparabilityType.CHEMICALS_OF_CONCERN]: 'Concern',
  [ComparabilityType.RITE]: '',
  [ComparabilityType.HEL]: '',
  [ComparabilityType.DIRTY_DOZEN]: '',
};
export const enum SustainabilityProperties {
  COSMOS = 'Csms:SstnbltNtrltLbls:560B3F2851566B427D8770E85E817B0B',
  BIODEGRADABILITY_LONG = 'BdgrdbltVltnLng:SstnbltBdgrdblt:AF2E301A622AD3CD413ED88A9B18A1CD',
  BIODEGRADABILITY_SHORT = 'BdgrdbltVltnShrt:SstnbltBdgrdblt:6002AE2AE30288A273BEEF08A6BFA8A6',
  RSPO = 'Rsp:SstnbltPrdctCrbnFtprnt:D90E154199E1EE9BE3D03AF47652DB6F',
  PCF_SCOTT_EXCLUDING_BIOGENIC = 'PcfScttXcldngBgnc:SstnbltPrdctCrbnFtprnt:08603D8F1E3594377B66C7860D01718B',
  PCF_SCOTT_VALIDATION_SCORE = 'PcfScttVldtnScr:SstnbltPrdctCrbnFtprnt:8A9D080FA75E4FC7428F219F652A874F',
  PCF_BIOGENIC = 'PcfBgnc:SstnbltPrdctCrbnFtprnt:759AF168464E71289010FD61EF3A314A',
  BIOGENIC_CARBON = 'BgncCrbn:SstnbltPrdctCrbnFtprnt:61AEB4F53B19AB6D4093855C3B2F62D1',
  TOTAL_CARBON_CONTENT = 'TtlCrbnCntnt:SstnbltPrdctCrbnFtprnt:1841BEF99F775493F08334ACB4F93299',
  RENEWABLE_CARBON_CONTENT = 'RnwblCrbnCntnt:SstnbltNtrlt:55D74E98C3CC5A51632227B035DBFC21',
}
