import { defineStore } from 'pinia';
import {
  ParamsOptions,
  BrandDetailReq,
  BrandDetail,
  ingredientFilter,
} from '@/api/types';
import { fetchBrandDetail } from '@/api';

interface State {
  brandDetail: BrandDetail | null;
  activeTab: string;
  activeIngredientFilters: ingredientFilter;
  scrollPosition: number;
}

export const useBrandDetailStore = defineStore('brandDetail', {
  state: (): State => ({
    brandDetail: null,
    activeTab: '',
    activeIngredientFilters: {
      category: '',
      subCategory: '',
      market: '',
      date: '',
      function: '',
    },
    scrollPosition: 0,
  }),

  actions: {
    async getBrandDetail(reqObj: BrandDetailReq) {
      const reqParams: ParamsOptions = {};
      reqObj.params.map((arr) => {
        reqParams[arr.name] = arr.value;
      });

      const responseData = await fetchBrandDetail(reqObj.brandId, reqParams);
      if (responseData) {
        this.brandDetail = responseData;
      }
    },

    resetBrandDetail() {
      this.brandDetail = null;
    },

    setActiveTab(tabName: string) {
      this.activeTab = tabName;
    },

    resetIngredientsFilters() {
      this.activeIngredientFilters.category = '';
      this.activeIngredientFilters.subCategory = '';
      this.activeIngredientFilters.market = '';
      this.activeIngredientFilters.date = '';
      this.activeIngredientFilters.function = '';
    },
  },
});
