/* eslint-disable  @typescript-eslint/no-explicit-any */
import api from '@/plugins/axiosApi';

export const fetchConsumerProductCats = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/heirarchicalreferences/world_category',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchConsumerProductMarket = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/country',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchBrandSelectionCats = async (params: any) => {
  const resObj: any = await api({
    url: `/api/masterdata/heirarchicalreferences/euromonitor_category/${
      params.id || ''
    }`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchMasterdataHierarchicalreferences = async (
  group: string,
  params: any
) => {
  const resObj: any = await api({
    url: `/api/masterdata/heirarchicalreferences/v2/${group}/${
      params.id || ''
    }`,
    method: 'get',
    params,
  });

  const { data } = resObj;
  return data;
};

export const fetchFormulationRegions = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/formulation_releasedfor',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchReleasedLocations = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/ingredient_region',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchBrandSelectionPricePos = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/price_pos',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchBrandSelectionLifestyle = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/world_lifestyle',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchBrandSelectionBrandPos = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/brand_pos',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchBrandSelectionMarket = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/country',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchMarketOverviewCountry = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/country',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchIngredientFunctions = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/ingredient_function',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchAllDiscussedTopics = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/discussed_topic',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchAllVideoClusters = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/video_cluster',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchAllRegions = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/heirarchicalreferences/region',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchRegions = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/v2/region',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchAllRawCategories = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/category',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchAllMaestroOilTypes = async (params?: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/maestro_oil_type',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchBiodegradabilityItems = async (params?: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/biodegradability',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

// TODO : Remove this API is deprecated
export const fetchMasterDataIngredients = async (params?: any) => {
  const resObj = await api({
    url: '/api/masterdata/ingredient',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchCompanies = async (params?: any) => {
  const { data } = await api({
    url: '/api/companies',
    method: 'get',
    params,
  });
  return data;
};
